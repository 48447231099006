import React from "react";

import { Grid } from "./Grid/Grid";
import { Search } from "./Search/Search";
import { Filters } from "./Filters/Filters";
import { ListProvider } from "./Context";

import { StyledList } from "./List.styled";

export const List = ({
  data,
  options,
  initFilters,
  Card,
  showSearch,
  showFilter,
}) => {
  return (
    <StyledList id="list">
      <ListProvider initFilters={initFilters} data={data} options={options}>
        {showSearch && <Search showFilter={showFilter} />}
        {showFilter && <Filters />}
        <Grid Card={Card} />
      </ListProvider>
    </StyledList>
  );
};

List.defaultProps = {
  data: [],
  options: null,
  initFilters: null,
  showSearch: true,
  showFilter: true,
};

export default List;
