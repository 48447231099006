import React from "react";

import {
  useAppContext,
  useSnackContext,
  useModalContext,
} from "../../../../context";

import { StyledRegister } from "./Register.styled";
import { RegisterForm } from "./Forms/RegisterForm";

const Register = () => {
  // get user context level setter
  const context = useAppContext();
  const contextSnack = useSnackContext();
  const contextModal = useModalContext();

  const handleRegister = (resp, status) => {
    let message = "";
    if (status === "success") {
      context.actions.setUser(resp["user"]["username"]);
      message = "Registered. Nice.";
      contextModal.actions.setModal("validate");
    } else {
      message = resp["message"];
    }
    contextSnack.actions.setSnackMessage(message);
    contextSnack.actions.setSnack(status);
  };

  return (
    <StyledRegister>
      <RegisterForm onRegister={handleRegister} />
    </StyledRegister>
  );
};

export default Register;
