import React from "react";
import { useHistory } from "react-router-dom";

import logo from "../../../../assets/logo2.png";
import { useModalContext } from "../../../../context";

import { StyledAbout } from "./About.styled";

export const About = () => {
  const contextModal = useModalContext();
  const history = useHistory();

  return (
    <StyledAbout id="rpanel">
      <div id="title">
        <img src={logo} id="logo" alt="logo" />
        <h3>Luminote: Financial Journal</h3>
      </div>
      <div>
        <p
          className="viewpolicy"
          onClick={() => {
            contextModal.actions.setModal("terms");
          }}
        >
          View Terms of Service
        </p>
        <p
          className="viewpolicy"
          onClick={() => {
            history.push("/privacy");
            contextModal.actions.setModal(false);
          }}
        >
          View Privacy Policy
        </p>
        <p>
          Need Help? Contact Us At: <br />
          <a className="email" href="mailto:support@luminote.app">
            support@splinteredglass.solutions
          </a>
        </p>
      </div>
      <div id="purpose">
        <p id="quote">“Plot your the story, Utilize the variance”</p>
        <p>
          What I mean by this cryptic phrase is that a well-kept journal allows
          for a more level perspective on life. Honestly, I’m not a big
          journaler myself, but during my brief forays into this habit, I’ve
          found that the meticulous observation of daily life has a calming and
          reflective effect. This may seem obvious to a dedicated journalist
          but was novel to me at the time. Now what I lacked in journaling, I
          made up for in budgeting. For many years, I maintained a detailed and
          complex budget. Too complex in fact, but more on that later. My
          dedication to the upkeep of the budget wasn’t out of fear or even
          financial responsibility, though I’m sure I claimed it as such.
          Rather I came to realize that this detailed categorization of our
          family's transactions provided a refreshing clarity to our life. We
          may be going broke but knowing the accounts hit zero in 5 months, is
          surprisingly more relaxing than just a general hunch that things
          don’t look good. It's often said that no solution can be found until
          the problem is quantified, and while I’m not sure if that holds true
          everywhere, it does seem to work for personal finances. A richer
          understanding of the cash flow does indeed highlight and encourage
          targeted remedial steps.
          <br />
          Now that’s more of a dramatic example, but the benefits of a
          financial journal don’t stop at curbing financial ruin. A reflection
          on, not only past transactions but expected future cash flow helps to
          give a much-needed perspective. It allows the journalist to properly
          appreciate the effects of those 5$ savings while maintaining a
          judicious response to the larger swings, both positive and negative.
          For example, a financial journal helps give well-deserved visibility
          to those small, hard to quantify decisions to eat in vs going out.
          And also helps assess the long-term implications of buying that new
          car, or getting that 4% raise.
          <br />
          <br />
          All too often we set money, its gain, and its retention, as the
          target in life. In doing so we miss out on so much of what life has
          to offer. Money plays an important role and responsible use of it is
          vital to success but when it gets elevated from a resource to a goal
          everyone suffers. We at Luminote seek to help move you closer to
          financial peace, but one of the keys to long term peace is removing
          “money” as the end goal. This tool enables you to visualize what your
          money is going towards and consider what that picture says about your
          targets and priorities. Celebrate that family vacation you saved
          months for with a picture embedded in your journal. Keep up with how
          that charity you support is doing with a highlight from their
          newsletter. Set a threshold alert for overspending on icecream. With
          Luminote, and a reasonable amount of personal accountability, you can
          realign your financial story.
          <br />
          <br />
          This all sounds great. So why don’t we all do this? Well, the word
          meticulous was used for a reason. Staying on top of a detailed budget
          is painful. As I write this, that beautiful budgeting spreadsheet I
          described is sitting unused for months. And at this point, there's no
          way I’m going back to manually categorize and reconcile all those
          transactions. This is where Luminote comes in. I vowed to not rebuild
          a budget tool until I built it as a user-friendly, automatic,
          simplified app. And the product you see today is the output of that
          vision.
          <br />
          <br />
          Now a lot of other budgeting tools can automate transaction
          categorization for you. And they do it very well. Machine learning is
          a powerful thing. However, most budgets lack two important elements:
          Accounting for the future A highlight of the real purposes behind our
          spending/saving
          <br />
          <br />
          Hindsight is great, and snapshots of where we’re at today are
          helpful, but to make fully informed financial decisions we need to
          account for expected future expenses and income. And this is where
          the Luminote workflow excels (oh and we do the auto-categorization
          stuff too). Obviously, no one can know the future, but we can
          reasonably anticipate we’ll need dog food each month. And a haircut
          every 3 months. And a gift for your mom on Mother’s Day. And probably
          some car maintenance each year. And that ol’ pesky mortgage/rent… you
          get the point. Balance all that against a salary or some expected
          income and you’ve got a projection. Cut off that salary and you’ve
          got your “find a new job” runway. Throw in an anticipated promotion
          in 3 months, and see how that changes the picture. All of this is
          possible through a powerful, approachable financial journal.
          <br />
          <br />
        </p>
        In short, Luminote seeks to:
        <ul>
          <li>
            Encourage a fresh perspective on budgeting that takes into account
            known factors and expected events to give a more holistic view of
            how decisions today affect the financial story tomorrow
          </li>
          <li>
            Complete with powerful functionality, intuitive automation, and
            endless customization{" "}
          </li>
          <li>
            Wrapped in an approachable framework designed to aid those who
            don’t have the time or may have struggled with budgeting in the
            past
          </li>
        </ul>
        <h3>
          We hope you’ll take up the pen and experience the benefits of writing
          your financial story.
        </h3>
      </div>
    </StyledAbout>
  );
};

export default About;
