import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import Amplify from "aws-amplify";
import config from "./aws-exports";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import App from "./App";
import "./index.css";

// where we at brah
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// dont turn on log rocket for local dev
if (isLocalhost) {
  LogRocket.init("r7uzuw/luminote");
  setupLogRocketReact(LogRocket);
}

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
  config.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, productionRedirectSignOut] =
  config.oauth.redirectSignOut.split(",");

// TODO: not sure what these are doing
const updatedAwsConfig = {
  ...config,
  oauth: {
    ...config.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

// setup amplify settings
Amplify.configure(updatedAwsConfig);

// render App to the root html element in the DOM
ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO: eventually need to set up a webvitals. Leanr more about this first
reportWebVitals();
// serviceWorker.unregister();
