import React, { useState, useContext, useEffect } from "react";

import { Auth, Hub } from "aws-amplify";

import { useSnackContext } from "../context/Snack";
import { SignIn as _SignIn } from "../scripts/User/SignIn";
import { GetActuals } from "../scripts/Actuals/GetActuals";

export const AppContext = React.createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

export function AppProvider(props) {
  const contextSnack = useSnackContext();

  const [user, setUser] = useState(null); // store dictionary of user settings
  const [loading, setLoading] = useState(false); // store dictionary of user settings
  const [actuals, setActuals] = useState([]); // store Actual lines
  const [expecteds, setExpecteds] = useState([]); // store all planned lines
  const [connectUrl, setConnectUrl] = useState(null); // TODO: do i need? not sure

  // TODO: func to update a single actuals record in db and state
  function updateActual() {}
  // TODO: func to update a single expected record in db and state
  function updateExpected() {}

  // hookup the Congito auth hub listener
  useEffect(() => {
    console.log("Congito hub listener");

    // message and status to use in snack
    var message = "";
    var status = "success";

    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          // TODO: add a welcome back snack
          console.log("signIn");
          break;
        case "cognitoHostedUI":
          // TODO: add a welcome back snack
          console.log("cognitoHostedUI");
          break;
        case "signOut":
          // TODO: add a goodbye snack
          console.log("signOut");
          setLoading(false);
          break;
        case "signIn_failure":
          // TODO: add an error snack
          console.log("signIn_failure");
          break;
        case "cognitoHostedUI_failure":
          // TODO: add an error snack
          console.log("cognitoHostedUI_failure");
          break;
        default:
          break;
      }
    });

    handleGetUser();
  }, []); // called only context onMount not on rerenders

  function handleGetUser() {
    Auth.currentAuthenticatedUser()
      .then((userData) => {
        _SignIn(userData, "", setUser, setLoading);
      })
      .catch(() => {
        console.log("Not signed in");
        setUser(null);
        return false;
      });
  }

  // after user is updated then call to get the users Data:
  // Actuals, Expecteds, Plits, etc...
  useEffect(() => {
    if (user?.MX?.guid) {
      GetActuals(user.MX.guid, null, setActuals);
    }
  }, [user]);

  function updateData(dataType, update) {
    console.log("update local copy");
    var existing = [];
    var setExisting = null;
    switch (dataType) {
      case "actuals":
        existing = actuals;
        setExisting = setActuals;
        break;
      case "expecteds":
        existing = expecteds;
        setExisting = setExpecteds;
        break;
      default:
        existing = actuals;
        setExisting = setActuals;
        break;
    }
    const updated = existing.map((obj) => {
      if (obj._id === update._id) {
        Object.assign(obj, update);
      }
      return obj;
    });
    setExisting(updated);
  }

  return (
    <AppContext.Provider
      value={{
        user: user,
        loading: loading,
        actuals: actuals,
        expecteds: expecteds,
        connectUrl: connectUrl,
        actions: {
          setUser: setUser,
          setLoading: setLoading,
          setActuals: setActuals,
          setExpecteds: setExpecteds,
          setConnectUrl: setConnectUrl,
          updateData: updateData,
        },
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

export const Consumer = AppContext.Consumer;
