import React from "react";

import { List } from "../../../../../common";
import { options } from "./serachOptions";
import { initFilters } from "./intiFilters";
import { ActualCard } from "./Card/Card";
import { StyledPageActuals } from "./PageActuals.styled";

const PageActuals = ({ actuals }) => {
  return (
    <StyledPageActuals id="pageactuals" height={options.listHeight}>
      {actuals.length && (
        <List
          data={actuals}
          options={options}
          initFilters={initFilters}
          Card={ActualCard}
          showFilter={false}
        />
      )}
    </StyledPageActuals>
  );
};

PageActuals.defaultProps = {
  data: [],
};

export default PageActuals;
