import React, { useEffect, useState } from "react";

import { useDrawerContext } from "../../../context";
import { DrawerSwitch } from "./Drawer.switch";
import { Drawer } from "./Drawer";

export function DrawerWrapper() {
  const contextDrawer = useDrawerContext();
  const [children, setChildren] = useState(false);

  useEffect(() => {
    // if the content of the Drawer context state is a string then its
    // denoting the content of the drawer from our switch
    if (typeof contextDrawer.drawer === "string") {
      // look up Drawer content refs
      setChildren(<DrawerSwitch mode={contextDrawer.drawer} />);
    } else {
      // else its closing it
      setChildren(contextDrawer.drawer);
    }
    return function () {
      setChildren(false);
    };
  }, [contextDrawer.drawer]);
  return (
    <Drawer
      children={children}
      dismiss={() => contextDrawer.actions.setDrawer(false)}
    />
  );
}
