import React, { useState, useEffect } from "react";

import { Slider as MuiSlider } from "@material-ui/core";
import Input from "@material-ui/core/Input";

import { StyledSlider } from "./Slider.styled";

export function SingleSlider({ id, settings, handleUpdate, handleBlur }) {
  let {
    Attribute,
    Value,
    Label,
    Min,
    Max,
    Step,
    Type,
    Orientation,
    Form,
  } = settings;
  const [val, setVal] = useState(Min);
  const [trigger, setTrigger] = useState(false);

  var marks = [];
  if (Type === "bool") {
    marks = [
      {
        value: -1,
        label: "No",
      },
      {
        value: 0,
        label: "All",
      },
      {
        value: 1,
        label: "Yes",
      },
    ];
  } else {
    marks = [
      {
        value: Min,
        label: Min,
      },
      {
        value: parseInt((Max - Min) / 2),
        label: parseInt((Max - Min) / 2),
      },
      {
        value: Max,
        label: Max,
      },
    ];
  }

  const handleChange = (event, newValue) => {
    setVal(newValue);
    if (Form) {
      event.target.id = Attribute;
      handleUpdate(event);
      return;
    }
    if (Type === "bool" && !newValue) {
      handleUpdate(newValue, false, false);
      return;
    }
    handleUpdate(newValue, true, false);
  };

  useEffect(() => {
    if (Value !== undefined) {
      setVal(Value);
    }
  }, [Value]);

  const handleClear = (event) => {
    if (Type === "bool") {
      setVal(0);
      if (Form) {
        event.target.id = Attribute;
        handleUpdate(event);
        return;
      }
      handleUpdate(0, false, false);
    } else {
      setVal(Min);
      if (Form) {
        event.target.id = Attribute;
        handleUpdate(event);
        return;
      }
      handleUpdate(Min, false, false);
    }
  };

  const handleInputChange = (event) => {
    let update = val;
    update = event.target.value === "" ? update : Number(event.target.value);
    setVal(update);
    setTrigger(!trigger);
  };

  const handleInputBlur = () => {
    if (val < { Min }) {
      setVal({ Min });
    } else if (val > { Max }) {
      setVal({ Max });
    }
    if (handleBlur) {
      handleBlur();
    }
  };

  return (
    <StyledSlider orientation={Orientation || "vertical"}>
      {settings && (
        <React.Fragment>
          {Label && <h3>{settings["Label"]}</h3>}
          <MuiSlider
            id={Attribute}
            step={Step}
            track={Type === "bool" ? false : "normal"}
            marks={marks}
            max={Max}
            min={Min}
            value={val}
            defaultValue={val}
            onChange={handleChange}
            valueLabelDisplay="auto"
            orientation={Orientation || "vertical"}
            aria-labelledby="range-slider"
          />
          <div id="inputs">
            <Input
              id="0"
              className="input"
              value={val}
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              inputProps={{
                step: { Step },
                min: { Min },
                max: { Max },
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </div>
          <span id="apply" onClick={() => handleUpdate(val, true, true)}>
            apply
          </span>
          <span id="clear" onClick={handleClear}>
            clear
          </span>
        </React.Fragment>
      )}
    </StyledSlider>
  );
}
