import { Auth } from "aws-amplify";

export async function AWSForgotPassword(username, values, handleResp) {
  try {
    var resp = await Auth.forgotPasswordSubmit(
      username,
      values.Code,
      values.NewPassword
    );
    handleResp(resp, "success");
  } catch (error) {
    console.log("error with getting code: ", error);
    handleResp(error, "error");
  }
}
