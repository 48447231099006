import styled from "styled-components";

export const StyledTag = styled.div`
  display: flex;
  justify-content: center;
}
  color: ${({ theme }) => theme.colors.dark};

  align-items: center;
  cursor: ${(props) => (props.onClick ? "pointer" : "normal")};

  #label {
    font-weight: 100;
  }
  #content {
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 0.8rem;
    margin: 0.25rem 0 0.25rem 0.25rem;
    padding: 0.25rem;
    background-color: ${(props) =>
      props.onClick ? props.theme.colors.white + "34" : null};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .borderOn {
    border: 2px solid ${({ theme }) => theme.colors.dark}bd;
    border-radius: 999px;
  }
  .icon {
    font-size: 1.1rem;
    & * {
      padding: 0;
    }
  }
`;
