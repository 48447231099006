import React from "react";

import { StyledEditor } from "./Editor.styled";

const Editor = () => {
  return (
    <StyledEditor id="content">
      <p>This will be the Editor page</p>
    </StyledEditor>
  );
};

export default Editor;
