import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Loader, Button } from "../../../../common";

import { useAppContext } from "../../../../../context";
import { AWSConfirmSignUp, AWSResendConfirmationCode } from "../scripts";
import { StyledForm } from "./Form.styled";

export const ValdiateForm = ({ handleConfirm, handleResend }) => {
  const context = useAppContext();

  return (
    <Formik
      initialValues={{
        Email: context["user"],
        ConfirmCode: "",
      }}
      validationSchema={Yup.object({
        Email: Yup.string().required("Required").email("valid email plz"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let success = await AWSConfirmSignUp(values, handleConfirm);
        if (!success) {
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <StyledForm id="registerConfimForm" onSubmit={formik.handleSubmit}>
          {formik.touched.Email && formik.errors.Email && (
            <p className="error">{formik.errors.Email}</p>
          )}
          <input
            className={formik.errors.Email ? "fail" : "success"}
            type="text"
            placeholder={context["user"] || "email"}
            name="Email"
            id="Email"
            value={formik.values.Email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <input
            className={formik.errors.ConfirmCode ? "fail" : "success"}
            type="text"
            placeholder="Evidence Code"
            name="ConfirmCode"
            id="ConfirmCode"
            value={formik.values.ConfirmCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autocomplete="off"
          />
          <p
            id="resend"
            className="links"
            onClick={() =>
              AWSResendConfirmationCode(context.user, handleResend)
            }
          >
            resend code
          </p>
          <span id="submit-section">
            <Button
              id="submit"
              type="submit"
              content={formik.isSubmitting ? "hmmm..." : "Lets check.. 🤨"}
              className={formik.isSubmitting ? "loading" : ""}
              disabled={formik.isSubmitting}
            />
            {formik.isSubmitting && <Loader />}
          </span>
        </StyledForm>
      )}
    </Formik>
  );
};
