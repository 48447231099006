import React from "react";

import { ThemeProvider } from "styled-components"; // wrapper for providing the theme data to entire app
import { GlobalStyles } from "./global"; // where we set the theme css implementations
import { theme } from "./theme"; // where we set the theme variables

import { AppProvider } from "./context/App";
import { ModalProvider } from "./context/Modal";
import { SnackProvider } from "./context/Snack";
import { DrawerProvider } from "./context/Drawer";

import { Wrapper } from "./components/Wrapper";
// import Select from "react-select";

// const options = [
//   { value: "blues", label: "Blues" },
//   { value: "rock", label: "Rock" },
//   { value: "jazz", label: "Jazz" },
//   { value: "orchestra", label: "Orchestra" },
// ];

function App() {
  return (
    // <Select options={options} />
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <SnackProvider>
        <DrawerProvider>
          <ModalProvider>
            <AppProvider>
              <Wrapper />
            </AppProvider>
          </ModalProvider>
        </DrawerProvider>
      </SnackProvider>
    </ThemeProvider>
  );
}

export default App;
