import React from "react";

import {
  useAppContext,
  useSnackContext,
  useModalContext,
} from "../../../../context";

import { ForgotForm } from "./Forms/ForgotForm";
import { StyledForgot } from "./Forgot.styled";

const Forgot = () => {
  const context = useAppContext();
  const contextSnack = useSnackContext();
  const contextModal = useModalContext();

  const handleForgot = (resp, status) => {
    let message = "";
    if (status === "success") {
      message = "Ok, time for the code";
    } else {
      message = resp["message"];
    }
    contextSnack.actions.setSnackMessage(message);
    contextSnack.actions.setSnack(status);

    if (status === "success") {
      context.actions.setUser(resp);
      contextModal.actions.setModal("forgot2");
    }
  };

  return (
    <StyledForgot>
      <h4 id="heading">Forgot Password? Been there...</h4>
      <p>Enter Email Address:</p>
      <ForgotForm onForgot={handleForgot} />
    </StyledForgot>
  );
};

export default Forgot;
