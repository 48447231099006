import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { Loader, Button } from "../../../../common";
import { AWSSignUp } from "../scripts";
import { StyledForm } from "./Form.styled";

export const RegisterForm = ({ onRegister }) => {
  return (
    <Formik
      initialValues={{
        DisplayName: "",
        // LastName: "",
        Email: "",
        // IsDeveloper: false,
        // DeveloperCompany: "",
        // DeveloperSite: "",
        Password: "",
        RetypePassword: "",
        Consent: false,
      }}
      validationSchema={Yup.object({
        DisplayName: Yup.string().required("required"),
        // LastName: Yup.string(),
        Email: Yup.string().required("required").email("valid email plz"),
        // IsDeveloper: Yup.boolean(),
        // DeveloperCompany: Yup.string(),
        // DeveloperSite: Yup.string().url("full url plz"),
        Password: Yup.string()
          .required("required")
          .min(8, "need 8 char")
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
            "needs an upper, lower, & number"
          ),
        RetypePassword: Yup.string()
          .required("required")
          .oneOf([Yup.ref("Password")], "passwords must match"),
        Consent: Yup.boolean()
          .test("consent", "required", (value) => value === true)
          .required("required"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let success = await AWSSignUp(values, onRegister);
        if (!success) {
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <StyledForm id="registerForm" onSubmit={formik.handleSubmit}>
          <h3>Create Account</h3>
          {formik.touched.DisplayName && formik.errors.DisplayName && (
            <p className="error">{formik.errors.DisplayName}</p>
          )}
          <input
            className={
              formik.touched.DisplayName && formik.errors.DisplayName
                ? "fail"
                : "success"
            }
            type="text"
            placeholder="display name"
            name="DisplayName"
            id="DisplayName"
            value={formik.values.DisplayName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {/* {formik.touched.LastName && formik.errors.LastName && (
            <p className="error">{formik.errors.LastName}</p>
          )}
          <input
            className={
              formik.touched.LastName && formik.errors.LastName
                ? "fail"
                : "success"
            }
            type="text"
            placeholder="last name"
            name="LastName"
            id="LastName"
            value={formik.values.LastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          /> */}
          {formik.touched.Email && formik.errors.Email && (
            <p className="error">{formik.errors.Email}</p>
          )}
          <input
            className={
              formik.touched.Email && formik.errors.Email ? "fail" : "success"
            }
            type="text"
            placeholder="email"
            name="Email"
            id="Email"
            value={formik.values.Email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <br />
          {/* <label>
            Are you a game developer?
            {formik.touched.IsDeveloper && formik.errors.IsDeveloper && (
              <p className="error">{formik.errors.IsDeveloper}</p>
            )}
            <input
              type="checkbox"
              name="IsDeveloper"
              id="IsDeveloper"
              value={formik.values.IsDeveloper}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </label>
          {formik.values.IsDeveloper ? (
            <div id="developer">
              {formik.touched.DeveloperCompany &&
                formik.errors.DeveloperCompany && (
                  <p className="error">{formik.errors.DeveloperCompany}</p>
                )}
              <input
                className={
                  formik.touched.DeveloperCompany &&
                  formik.errors.DeveloperCompany
                    ? "fail"
                    : "success"
                }
                type="text"
                placeholder="company name"
                name="DeveloperCompany"
                id="DeveloperCompany"
                value={formik.values.DeveloperCompany}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.DeveloperSite && formik.errors.DeveloperSite && (
                <p className="error">{formik.errors.DeveloperSite}</p>
              )}
              <input
                className={
                  formik.touched.DeveloperSite && formik.errors.DeveloperSite
                    ? "fail"
                    : "success"
                }
                type="url"
                placeholder="company website"
                name="DeveloperSite"
                id="DeveloperSite"
                value={formik.values.DeveloperSite}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          ) : (
            <div></div>
          )} */}
          {formik.touched.Password && formik.errors.Password && (
            <p className="error">{formik.errors.Password}</p>
          )}
          <input
            className={
              formik.touched.Password && formik.errors.Password
                ? "fail"
                : "success"
            }
            type="password"
            placeholder="password"
            name="Password"
            id="Password"
            value={formik.values.Password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.RetypePassword &&
            formik.values.RetypePassword !== formik.values.Password && (
              <p className="error">passwords dont match</p>
            )}
          <input
            className={
              formik.touched.RetypePassword &&
              formik.values.RetypePassword !== formik.values.Password
                ? "fail"
                : "success"
            }
            type="password"
            placeholder="retype password"
            autoComplete="off"
            name="RetypePassword"
            id="RetypePassword"
            value={formik.values.RetypePassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.Consent && formik.errors.Consent && (
            <p className="error">{formik.errors.Consent}</p>
          )}
          <label>
            Accept Terms and Conditions
            <input
              type="checkbox"
              name="Consent"
              id="Consent"
              value={formik.values.Consent}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </label>
          <span id="submit-section">
            <Button
              id="submit"
              type="submit"
              content={formik.isSubmitting ? "Lets Go!" : "Get Started"}
              className={formik.isSubmitting ? "loading" : ""}
              disabled={formik.isSubmitting}
            />
            {formik.isSubmitting && <Loader />}
          </span>
        </StyledForm>
      )}
    </Formik>
  );
};
