import React from "react";
import { useHistory } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import { useModalContext } from "../../../context/Modal";
import logo from "../../../assets/logo2.png";
import { StyledFooter } from "./Footer.styled";

function Footer() {
  const contextModal = useModalContext();
  const history = useHistory();

  const handleScrollReset = () => {
    window.scrollTo(0, 0);
  };

  return (
    <StyledFooter id="footer">
      <IconButton
        id="about"
        aria-label="about"
        className="links"
        onClick={() => {
          contextModal.actions.setModal("about");
        }}
      >
        <span>about</span>
      </IconButton>
      <IconButton
        id="contact"
        aria-label="contact"
        className="links"
        onClick={() => {
          contextModal.actions.setModal("terms");
        }}
      >
        <span>terms</span>
      </IconButton>
      <IconButton
        aria-label="landingIcon"
        id="landingIcon"
        className="icon-button"
        onClick={() => {
          history.push("/");
        }}
      >
        <img src={logo} id="navlogo" alt="navlogo" />
      </IconButton>
      <IconButton
        id="privacy"
        aria-label="privacy"
        className="links"
        onClick={() => {
          history.push("/privacy");
        }}
      >
        <span>privacy</span>
      </IconButton>
      <IconButton
        id="to-top"
        aria-label="to-top"
        className="links"
        onClick={handleScrollReset}
      >
        {/* <span>top</span> */}
        <ArrowUpwardIcon id="to-top-icon" />
      </IconButton>
    </StyledFooter>
  );
}

export default Footer;
