import React from "react";

import { StyledLanding } from "./Landing.styled";

const Landing = () => {
  return (
    <StyledLanding id="content">
      <p>This will be a landing page</p>
    </StyledLanding>
  );
};

export default Landing;
