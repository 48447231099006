export const options = {
  includeScore: true, //include the search score in the result
  id: "_id", // what is the identified attribute in this data
  elementHeight: 66, // height of the cards
  listHeight: 66 * 5, // number of cards to show
  // Search in `author` and in `tags` array
  keys: [
    {
      name: "description",
      weight: 1,
    },
    {
      name: "original_description",
      weight: 0.3,
    },
    {
      name: "_id",
      weight: 0.2,
    },
  ],
};
