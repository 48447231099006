import React from "react";

import { StyledFuture } from "./Future.styled";

const Future = () => {
  return (
    <StyledFuture id="content">
      <p>This will be the Future page</p>
    </StyledFuture>
  );
};

export default Future;
