import styled from "styled-components";

export const StyledWrapper = styled.div`
  /* max-height: 100%; */
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  display: grid;
  grid-template-columns: 0 100% 0;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "lpanel top rpanel"
    "lpanel content rpanel"
    "lpanel footer rpanel";
  align-items: start;

  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.middle},
    ${({ theme }) => theme.colors.white},
    ${({ theme }) => theme.colors.white}
  );

  /* Children Basics */

  & #navBar {
    grid-area: top;
  }

  & #content {
    grid-area: content;
    text-align: center;

  }

  & #footer {
    grid-area: footer;
    text-align: center;
  }

  & #lpanel {
    grid-area: lpanel;
    height: 100%;
  }

  & #rpanel {
    grid-area: rpanel;
    height: 100%;
  }
`;
