import React from "react";

import { StyledTag } from "./Tag.styled";

export function Tag({ id, label, content, onClick, border, isIcon }) {
  var _class = "";
  if (border) {
    _class += "borderOn ";
  }
  if (isIcon) {
    _class += "icon ";
  }

  return (
    <StyledTag onClick={onClick || null} id={id} className={_class}>
      {!isIcon && <label id="label">{label}</label>}
      {!isIcon && <div id="content">{content}</div>}
      {isIcon && content}
    </StyledTag>
  );
}
Tag.defaultProps = {
  id: "tag",
  label: null,
  content: "",
  border: true,
  isIcon: false,
};
