import { Auth } from "aws-amplify";

export async function AWSSignUp(data, handleResp) {
  // console.log("Starting SignUp w/");
  // AWS Auth SignUp
  try {
    const resp = await Auth.signUp({
      username: data["Email"].toLowerCase(),
      password: data["Password"],
      attributes: {
        email: data["Email"].toLowerCase(),
        name: data["DisplayName"],
        // "custom:site":
        //   data["DeveloperSite"] == null ? "" : data["DeveloperSite"],
        // "custom:company":
        //   data["DeveloperCompany"] == null ? "" : data["DeveloperCompany"],
      },
    });
    handleResp(resp, "success");
    return true;
  } catch (err) {
    handleResp(err, "error");
    return false;
  }
}
