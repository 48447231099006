import axios from "axios";

export async function Refresh(data) {
  console.log("Refresh Member");
  var url;
  var key;
  if (process.env.NODE_ENV === "development") {
    key = process.env.REACT_APP_LUMINOTE_XAPIKEY_DEV;
    url = "http://localhost:3001/dev/member/refresh";
  } else {
    key = process.env.REACT_APP_LUMINOTE_XAPIKEY;
    url =
      "https://ah1m82mw33.execute-api.us-east-2.amazonaws.com/dev/member/refresh";
  }

  const heads = {
    "Content-Type": "application/json",
    Authorization: "",
    "x-api-key": key,
  };

  var res = null;
  await axios({
    method: "post",
    url: url,
    headers: heads,
    data: data,
  })
    .then(function (response) {
      res = response["data"];
    })
    .catch(function (error) {
      console.log(error);
      res = null;
    });
  return res;
}
