import React, { useEffect } from "react";

import { useAppContext, useModalContext } from "../../../../context";
import { Connect as _Connect } from "../../../../scripts/User/Connect";

import { StyledConnect } from "./Connect.styled";

const Connect = () => {
  const context = useAppContext();
  const contextModal = useModalContext();

  var mxConnect = new window.MXConnect({
    id: "widget",
    iframeTitle: "Connect",

    onEvent: function (type, payload) {
      console.log("onEvent", type, payload);
      switch (type) {
        case "mx/connect/memberConnected":
          var members = context.user.Members ? context.user.Members : [];
          members.push({ guid: payload.member_guid });
          var update_user = context.user;
          update_user["Members"] = members;
          context.actions.setUser(update_user);
          break;
        default:
          return;
      }
    },
    config: {
      ui_message_version: 4,
    },
    targetOrigin: "*",
  });

  useEffect(() => {
    _Connect({ mx_guid: context.user.MX.guid })
      .then((result) => {
        console.log("url=" + result);
        mxConnect.load(result);
      })
      .catch(contextModal.actions.setModal(false));
  }, []);

  return (
    <StyledConnect>
      <div id="widget"></div>
    </StyledConnect>
  );
};

export default Connect;
