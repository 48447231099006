import styled from "styled-components";

export const StyledFilters = styled.div`
  transition: max-height 0.15s ease-out;
  grid-area: filters;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 60px;
  width: 100%;
  z-index: 999;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.grey}6b;
  max-height: ${(props) => (props.filtersOpen ? "60px" : "0px")};
  height: 60px;
  padding: 0 0.25rem;
  /* opacity: 0.9; */
  border-bottom: 1px solid ${({ theme }) => theme.colors.white}54;
  #reset {
    color: ${({ theme }) => theme.colors.white};
  }
  .MuiChip-icon {
    position: relative;
    overflow: visible;
    top: -6px;
    font-size: 1.25rem;
  }
  .MuiChip-root {
    background-color: ${({ theme }) => theme.colors.light};
  }
  & #reset,
  #chips {
    display: ${(props) => (props.filtersOpen ? "block" : "none")};
  }
  #chips {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;

    overflow-x: scroll;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .chip {
    margin-right: 0.5rem;
    /* min-width: 50px; */
    text-align: center;
    justify-content: end;
  }
  .MuiPopover-paper {
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    background-color: ${({ theme }) => theme.colors.standout};
  }
`;
