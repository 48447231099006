/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:ce39c03f-3477-469d-816b-e09b19006c60",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_VNtagoufx",
    "aws_user_pools_web_client_id": "4625s2ehmuha4cdge0vsn3qdt2",
    "oauth": {
        "domain": "luminote-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.luminote.app/",
        "redirectSignOut": "http://localhost:3000/,https://www.luminote.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
