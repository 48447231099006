import React from "react";

import {
  useAppContext,
  useSnackContext,
  useModalContext,
} from "../../../../../../../../context";
import { UpsertActual } from "../../../../../../../../scripts/Actuals/UpsertActuals";

import { RiStickyNoteFill } from "react-icons/ri";
import { FiTrash } from "react-icons/fi";

import { Form } from "./Forms/Form";
import { StyledEdit } from "./Edit.styled";

const Edit = ({ data }) => {
  const context = useAppContext();
  const contextSnack = useSnackContext();
  const contextModal = useModalContext();

  const handleEdit = async (values) => {
    // then save the change to the app data
    console.log("Update in app data");
    // need to save the change to the db
    console.log("Save to db");
    const query = { user_guid: data.mx_guid, guid: data.guid };
    const update = values;
    let resp_u = await UpsertActual(query, update);

    context.actions.updateData("actuals", { ...update, _id: data._id });

    // fire off a snack
    let message = resp_u;
    let status = "fail";
    if (resp_u === true) {
      status = "success";
      message = "Updated!";
    }
    contextSnack.actions.setSnackMessage(message);
    contextSnack.actions.setSnack(status);

    // close the modal
    if (status === "success") {
      contextModal.actions.setModal(false);
      return true;
    }
    return false;
  };

  return (
    <StyledEdit>
      <div id="editHeader">
        <h3>Modify Entry</h3>
        <RiStickyNoteFill className="icon" />
        <FiTrash className="icon" />
      </div>
      <Form handleEdit={handleEdit} data={data} />
    </StyledEdit>
  );
};

export default Edit;
