export function checkDataPoint(game) {
  let include = true;
  switch (this.Attribute) {
    case "Rank":
      if (this.Value.length > 1) {
        include =
          game["rank"] >= Math.min(...this.Value) &&
          game["rank"] <= Math.max(...this.Value);
      } else {
        include = game["rank"] >= this.Value;
      }
      break;
    case "Rating":
      if (this.Value.length > 1) {
        include =
          game["average_user_rating"] >= Math.min(...this.Value) &&
          game["average_user_rating"] <= Math.max(...this.Value);
      } else {
        include = game["average_user_rating"] >= this.Value;
      }
      break;
    case "Duration":
      if (this.Value.length === 1) {
        include = game["min_playtime"] / 60 >= this.Value;
      } else if (Math.max(...this.Value) === this.Max) {
        // if range is at max on upper then only use min
        include = game["max_playtime"] / 60 >= Math.min(...this.Value);
      } else {
        // use both
        include =
          Math.min(...this.Value) >= game["min_playtime"] / 60 ||
          Math.max(...this.Value) <= game["max_playtime"] / 60;
      }
      break;
    case "Players":
      if (this.Value.length === 1) {
        include = game["min_players"] >= this.Value;
      } else if (Math.max(...this.Value) === this.Max) {
        // if range is at max on upper then only use min
        include = game["max_players"] >= Math.min(...this.Value);
      } else {
        // use both
        include =
          Math.min(...this.Value) >= game["min_players"] ||
          Math.max(...this.Value) <= game["max_players"];
      }
      break;
    case "Replay":
      if (this.Value.length > 1) {
        include =
          game["Replay"] >= Math.min(...this.Value) &&
          game["Replay"] <= Math.max(...this.Value);
      } else {
        include = game["Replay"] >= this.Value;
      }
      break;
    case "Complexity":
      if (this.Value.length > 1) {
        include =
          game["Complexity"] >= Math.min(...this.Value) &&
          game["Complexity"] <= Math.max(...this.Value);
      } else {
        include = game["Complexity"] >= this.Value;
      }
      break;
    case "Social":
      if (this.Value.length > 1) {
        include =
          game["Social"] * 10 >= Math.min(...this.Value) &&
          game["Social"] * 10 <= Math.max(...this.Value);
      } else {
        include = game["Social"] * 10 >= this.Value;
      }
      break;
    case "Speed":
      if (this.Value.length > 1) {
        include =
          game["Speed"] * 10 >= Math.min(...this.Value) &&
          game["Speed"] * 10 <= Math.max(...this.Value);
      } else {
        include = game["Speed"] * 10 >= this.Value;
      }
      break;
    case "Luck":
      if (this.Value.length > 1) {
        include =
          game["Luck"] * 10 >= Math.min(...this.Value) &&
          game["Luck"] * 10 <= Math.max(...this.Value);
      } else {
        include = game["Luck"] * 10 >= this.Value;
      }
      break;
    case "Memory":
      if (this.Value.length > 1) {
        include =
          game["Memory"] * 10 >= Math.min(...this.Value) &&
          game["Memory"] * 10 <= Math.max(...this.Value);
      } else {
        include = game["Memory"] * 10 >= this.Value;
      }
      break;
    case "Strategy":
      if (this.Value.length > 1) {
        include =
          game["Strategy"] * 10 >= Math.min(...this.Value) &&
          game["Strategy"] * 10 <= Math.max(...this.Value);
      } else {
        include = game["Strategy"] * 10 >= this.Value;
      }
      break;
    default:
      include = true;
  }
  return include;
}
