import styled from "styled-components";

export const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.dark};
  border: none;
  margin: 0 1rem;
  height: fit-content;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  letter-spacing: 0.1rem;
  text-transform: lowercase;
  font-size: 1.25rem;
  font-weight: 100;
  background: ${({ theme }) => theme.colors.white} none repeat scroll 0% 0%;
  border-color: ${({ theme }) => theme.colors.dark};
  box-shadow: 2px 2px 4px ${({ theme }) => theme.colors.white}54;
  transition: 0.15s all;

  &:hover {
    background: ${({ theme }) => theme.colors.light};
    color: ${({ theme }) => theme.colors.dark};
    box-shadow: 1px 1px 4px ${({ theme }) => theme.colors.dark};
    cursor: pointer;
  }
  &:active {
    /* transform: translateY(1px); */
    border: 1px solid ${({ theme }) => theme.colors.light};
    opacity: 0.9;
  }

  &.dark {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.dark} none repeat scroll 0% 0%;
    box-shadow: 1px 1px 4px ${({ theme }) => theme.colors.white};
    &:hover {
      /* border: 1px solid ${({ theme }) => theme.colors.white}; */
      color: ${({ theme }) => theme.colors.light};
      box-shadow: 1px 1px 4px ${({ theme }) => theme.colors.dark};
    }
  }
`;
