import axios from "axios";

export async function _Upsert(body) {
  console.log('Upsert')
  var url;
  var key;
  if (process.env.NODE_ENV === "development") {
    key = process.env.REACT_APP_LUMINOTE_XAPIKEY_DEV;
    url = "http://localhost:3001/dev/upsert";
  } else {
    key = process.env.REACT_APP_LUMINOTE_XAPIKEY;
    url =
      "https://ah1m82mw33.execute-api.us-east-2.amazonaws.com/dev/upsert";
  }

  const heads = {
    "Content-Type": "application/json",
    Authorization: "",
    "x-api-key": key,
  };

  var res = null;
  await axios({
    method: "post",
    url: url,
    headers: heads,
    data: body,
  })
    .then(function (response) {
      res = response["data"]["data"]
    })
    .catch(function (error) {
      console.log(error);
      res = null;
    });
  return res
}