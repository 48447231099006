import React from "react";

import { StyledDocs } from "./Docs.styled";

const Docs = () => {
  return (
    <StyledDocs id="content">
      <p>This will be the Docs page</p>
    </StyledDocs>
  );
};

export default Docs;
