import React from "react";

import { useSnackContext, useModalContext } from "../../../../context";

import { StyledValidate } from "./Validate.styled";
import { ValdiateForm } from "./Forms/ValidateForm";

const Validate = () => {
  // get user context level setter
  const contextSnack = useSnackContext();
  const contextModal = useModalContext();

  const handleConfirm = (resp, status) => {
    let message = "";
    if (status === "success") {
      message = "Fine, I believe you.";
    } else {
      message = resp["message"];
    }
    contextSnack.actions.setSnackMessage(message);
    contextSnack.actions.setSnack(status);
    if (status === "success") {
      contextModal.actions.setModal("signin");
    }
  };

  const handleResend = (resp, status) => {
    let message = "";
    if (status === "success") {
      message = "Sent";
    } else {
      message = resp["message"];
    }
    contextSnack.actions.setSnackMessage(message);
    contextSnack.actions.setSnack(status);
  };

  return (
    <StyledValidate>
      <h3>You sure, you're not a robot?</h3>
      Psst, check your email..
      <ValdiateForm
        handleConfirm={handleConfirm}
        handleResend={handleResend}
      />
    </StyledValidate>
  );
};

export default Validate;
