import styled from "styled-components";

export const StyledPageActuals = styled.div`
  animation: createPage 1.5s;

  border-radius: 0.5rem;

  @keyframes createPage {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
