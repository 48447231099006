import React from "react";

import { StyledPageHead } from "./PageHead.styled";

const PageHead = ({ page, handleFlipPage }) => {
  return (
    <StyledPageHead id="pagehead">
      <div id="controllvl">
        <button onClick={() => handleFlipPage(page.Current - 1)}>◀</button>
        <span>Page {page.Current}</span>
        <button onClick={() => handleFlipPage(page.Current + 1)}>▶</button>
      </div>
      <p id="dateslvl">
        <span className="detail">{page.Start.toLocaleDateString()}</span>
        <span className="detail">{page.End.toLocaleDateString()}</span>
      </p>
    </StyledPageHead>
  );
};

export default PageHead;
