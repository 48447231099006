import React from "react";

import { StyledPagePie } from "./PagePie.styled";

const PagePie = () => {
  return (
    <StyledPagePie id="content">
      <p>This will be the PagePie page</p>
    </StyledPagePie>
  );
};

export default PagePie;
