import React, { useState, useEffect } from "react";

import { Slider as MuiSlider } from "@material-ui/core";
import Input from "@material-ui/core/Input";

import { StyledSlider } from "./Slider.styled";

export function DualSlider({ id, settings, handleUpdate, handleBlur }) {
  let {
    Attribute,
    Value,
    Label,
    Min,
    Max,
    Step,
    Type,
    Orientation,
    Form,
  } = settings;
  const [val, setVal] = useState([Min, Max]);
  const [trigger, setTrigger] = useState(false);

  var marks = [
    {
      value: Min,
      label: Min,
    },
    {
      value: parseInt((Max - Min) / 2),
      label: parseInt((Max - Min) / 2),
    },
    {
      value: Max,
      label: Max,
    },
  ];

  const handleChange = (event, newValue) => {
    setVal(newValue);
    if (Form) {
      event.target.id = Attribute;
      handleUpdate(event);
      return;
    }
    handleUpdate(newValue, true, false);
  };

  useEffect(() => {
    if (Value !== undefined) {
      setVal(Value);
    }
  }, [Value]);

  const handleClear = (event) => {
    setVal([Min, Max]);
    if (Form) {
      event.target.id = Attribute;
      handleUpdate(event);
      return;
    }
    handleUpdate([Min, Max], false, false);
  };

  const handleInputChange = (event) => {
    let update = val;
    update[parseInt(event.target.id)] =
      event.target.value === ""
        ? update[parseInt(event.target.id)]
        : Number(event.target.value);
    setVal(update);
    setTrigger(!trigger);
  };

  const handleInputBlur = (event) => {
    let update = val;
    if (val[parseInt(event.target.id)] < { Min }) {
      update[parseInt(event.target.id)] = Min;
    } else if (val[parseInt(event.target.id)] > { Max }) {
      update[parseInt(event.target.id)] = Max;
    }
    setVal(update);
    setTrigger(!trigger);
    if (handleBlur) {
      handleBlur();
    }
  };
  return (
    <StyledSlider orientation={Orientation || "vertical"}>
      {settings && (
        <React.Fragment>
          {Label && <h3>{settings["Label"]}</h3>}
          <MuiSlider
            id={Attribute}
            step={Step}
            track="normal"
            marks={marks}
            max={Max}
            min={Min}
            value={val}
            defaultValue={val}
            onChange={handleChange}
            valueLabelDisplay="auto"
            orientation={Orientation || "vertical"}
            aria-labelledby="range-slider"
          />
          <div id="inputs">
            <Input
              id="0"
              className="input"
              value={val[0]}
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              inputProps={{
                step: { Step },
                min: { Min },
                max: { Max },
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
            <Input
              id="1"
              className="input"
              value={val[1]}
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              inputProps={{
                step: { Step },
                min: { Min },
                max: { Max },
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </div>
          <span id="apply" onClick={() => handleUpdate(val, true, true)}>
            apply
          </span>
          <span id="clear" onClick={handleClear}>
            clear
          </span>
        </React.Fragment>
      )}
    </StyledSlider>
  );
}
