import React, { useEffect, useState } from "react";

import { useListContext } from "../Context";
import { Loader } from "../../../common";
import { StyledGrid } from "./Grid.styled";

var Infinite = require("react-infinite");

export function Grid({ Card }) {
  const listContext = useListContext();

  const [multis, setMultis] = useState([]);

  // take raw data from context and limit it based on
  useEffect(() => {
    var limitData = [];
    // if there are searchResults then limit the data based on that
    if (listContext.searchResult) {
      // there ARE searched games
      limitData = listContext.searchResult;
    } else {
      // no search so use all
      limitData = listContext.fullData;
    }

    // if there are filter results then continue to limit based on those
    if (listContext.filterResult) {
      // for each of the filters
      limitData = limitData.filter((point) => {
        if (
          !listContext.filterResult.includes(point[listContext.options.id])
        ) {
          return false;
        }
        return true;
      });
    }

    listContext.actions.setDataLimited(limitData);
  }, [
    listContext.fullData,
    listContext.searchResult,
    listContext.filterResult,
  ]);

  function handleSelected(_id) {
    var current = multis;
    // if its already selected then remove it
    if (current.includes(_id)) {
      current = current.filter((c) => c !== _id);
      setMultis(current);
    } else {
      //add it
      current.push(_id);
      setMultis(current);
    }
    console.log(`current len: ${current.length}`);
  }

  return (
    <React.Fragment>
      {listContext.dataLimited.length ? (
        <StyledGrid id="grid">
          <Infinite
            id="infinate"
            containerHeight={listContext.listOptions?.listHeight + 100}
            elementHeight={listContext.listOptions?.elementHeight ?? 66}
          >
            {listContext.dataLimited.map(function (item, index) {
              const seli = multis.includes(item._id);
              return (
                <Card
                  className="card"
                  key={index}
                  data={item}
                  height={listContext.listOptions?.elementHeight}
                  selected={seli}
                  handleSelected={handleSelected}
                  // overlay={multis.length > 0}
                />
              );
            })}
          </Infinite>
        </StyledGrid>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
}
