import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle` 
  html, body {
    margin: 0;
    padding: 0;
    max-height: 100vh;
  }
  *, *::after, *::before {
    box-sizing: border-box;
  }
  body {
    background: ${({ theme }) => {
      return theme.dark;
    }};
    color: ${({ theme }) => theme.colors.white};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  .ctooltip {
    color: ${({ theme }) => theme.colors.white};
    inset: -5px auto auto 0px !important;

  }

  * > ::-webkit-scrollbar {
    /* width: 0.5rem;
    height: 0.5rem; */
  }
  
  * > ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px ${({ theme }) => theme.colors.light};
    background: linear-gradient(180deg,
      ${({ theme }) => theme.colors.dark}, ${({ theme }) =>
  theme.colors.dark});
    border-radius: 0.25rem;
  }
  
  * > ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.white}54;
    outline: 1px solid slategrey;
    border-radius: 0.25rem;
    &:hover{
      background-color: ${({ theme }) => theme.colors.white}c7;
    }
  }
  * > ::-webkit-scrollbar-track-piece ::-webkit-scrollbar-corner{
    display: none;
    width: 0;
    height: 0;
    background: transparent;
  }
`;
