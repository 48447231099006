import styled from "styled-components";

export const StyledEdit = styled.div`
  overflow: scroll;
  margin: 0.5rem;
  width: 325px;

  & #editHeader {
    width: calc(100%-1rem);
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    margin: 0 0.5rem 1rem;
    & .icon {
      font-size: 1.25rem;
      margin: 0 0 0 1rem;
    }
    & h3 {
      font-weight: 100;
      margin: 0.5rem 0;
      flex: auto;
    }
  }
`;
