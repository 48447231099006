import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { useAppContext } from "../../../../../../../../../context";

import { Button, Loader } from "../../../../../../../../common";
import { StyledForm } from "./Form.styled";

export const Form = ({ handleEdit, data }) => {
  const context = useAppContext();
  const [accts, setAccts] = useState([]);

  // use effect to get the name of the account
  useEffect(() => {
    const acct_options = context?.user?.MX?.Accounts.map((act) => ({
      value: act.guid,
      label: `${act.name} (${act.institution_code.replaceAll("_", " ")})`,
    }));
    setAccts(acct_options);
  }, [data, context.user]);

  return (
    <Formik
      initialValues={{
        _description: data?._description || "",
        _amount: data?._amount || null,
        _account_guid: data?._account_guid,
        posted_at: data?.posted_at || null,
      }}
      validationSchema={Yup.object({
        _description: Yup.string().required("Must have _description"),
        _amount: Yup.number().required("Must have _amount"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (values?._amount > 0) {
          values["_type"] = "CREDIT";
        } else {
          values["_type"] = "DEBIT";
        }
        values["_amount"] = parseFloat(values?._amount);
        let success = await handleEdit(values);
        if (!success) {
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <StyledForm id="EditForm" onSubmit={formik.handleSubmit}>
          {formik.touched._description && formik.errors._description && (
            <p className="error">{formik.errors._description}</p>
          )}
          <div className="row">
            <input
              className={formik.errors._description ? "fail" : "success"}
              _type="text"
              placeholder={data?._description || ""}
              autoComplete="on"
              name="_description"
              id="_description"
              value={formik.values._description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {formik.touched._amount && formik.errors._amount && (
            <p className="error">{formik.errors._amount}</p>
          )}
          <div className="row">
            {/* <label htmlFor="_description">_Amount</label> */}
            <div id="amountBox">
              <span className={data?._type === "CREDIT" ? "green" : "white"}>
                $
              </span>
              <input
                className={formik.errors._amount ? "fail" : "success"}
                _type="number"
                placeholder={
                  (data?._type == "CREDIT"
                    ? data?._amount
                    : -1 * data?._amount) || null
                }
                autoComplete="on"
                name="_amount"
                id="_amount"
                value={formik.values._amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <Select
              isSearchable={true}
              classNamePrefix={"Select"}
              className={`${
                formik.errors._account_guid ? "fail" : "success"
              } dropdown`}
              autoComplete="on"
              name="_account_guid"
              id="_account_guid"
              value={
                accts
                  ? accts.find(
                      (option) => option.value === formik.values._account_guid
                    )
                  : ""
              }
              onChange={(option) =>
                formik.setFieldValue("_account_guid", option.value)
              }
              onBlur={formik.handleBlur}
              options={accts}
            />
          </div>
          <Button
            id="submit"
            _type="submit"
            content={formik.isSubmitting ? "Thinking" : "Update"}
            className={formik.isSubmitting ? "loading" : ""}
            disabled={formik.isSubmitting}
          />
          {formik.isSubmitting && <Loader />}
        </StyledForm>
      )}
    </Formik>
  );
};
