import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// import { PageProvider } from "../../../context/Page";
import {
  NotFound,
  Home,
  Past,
  Present,
  Future,
  Editor,
  Account,
  Privacy,
  Terms,
  Docs,
  Landing,
} from "../Page";

function Page() {
  return (
    // <PageProvider>
    <Switch>
      <Route path="/home" render={(props) => <Home {...props} />} />
      <Route path="/editor" render={(props) => <Editor {...props} />} />
      <Route path="/past" render={(props) => <Past {...props} />} />
      <Route path="/present" render={(props) => <Present {...props} />} />
      <Route path="/future" render={(props) => <Future {...props} />} />
      <Route path="/account" render={(props) => <Account {...props} />} />
      <Route path="/terms" render={(props) => <Terms {...props} />} />
      <Route path="/docs" render={(props) => <Docs {...props} />} />

      <Route path="/privacy" render={(props) => <Privacy {...props} />} />
      <Route path="/not-found" component={NotFound} />
      <Route path="/" render={(props) => <Landing {...props} />} />
      <Redirect from="/" to="/landing" />
    </Switch>
    // </PageProvider>
  );
}

export default Page;
