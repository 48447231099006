import React, { useState, useEffect } from "react";

import { useAppContext } from "../../../../context";
import { Loader } from "../../../common";

import { StyledPresent } from "./Present.styled";
import { PageHead, PageActuals, PagePie } from "./elements";

const Present = () => {
  const context = useAppContext();
  const [page, setPage] = useState(null);
  const [pageActuals, setPageActuals] = useState([]);

  //  on intial load set the page to the current page
  useEffect(() => {
    if (context?.user?.Page?.Current) {
      CalcPageBounds(context.user.Page);
    }
  }, [context.user?.Page]);

  useEffect(() => {
    if (context.actuals && page) {
      CalcPageActuals(page.Current);
    }
  }, [page, context.actuals]);

  function handleFlipPage(pageNumber) {
    const updt = { ...page };
    updt["Current"] = pageNumber;
    CalcPageBounds(updt);
  }

  //calc the actuals and expected for this page
  function CalcPageActuals(pageCurrent) {
    if (context.actuals) {
      var pagelines = context.actuals.filter((a) => a["Page"] === pageCurrent);
      setPageActuals(pagelines);
    }
  }

  //calc the start and end dates of the page
  function CalcPageBounds(page) {
    var start = new Date(page.PageIndexRef);
    var end = new Date(page.PageIndexRef);

    switch (page.PageSizeUnit) {
      case "day":
        start.setDate(start.getDate() + page.Current);
        end.setDate(end.getDate() + (page.Current + 1));
        break;
      case "week":
        start.setDate(start.getDate() + page.Current * 7);
        end.setDate(end.getDate() + (page.Current + 1) * 7);
        break;
      case "month":
        start.setMonth(start.getMonth() + page.Current);
        end.setMonth(end.getMonth() + page.Current + 1);
        break;
      default:
        break;
    }

    page["Start"] = start;
    page["End"] = end;
    setPage(page);
  }

  return (
    <React.Fragment>
      {page ? (
        <StyledPresent id="content">
          <PageHead page={page} handleFlipPage={handleFlipPage}></PageHead>
          <PageActuals page={page} actuals={pageActuals}></PageActuals>
          <PagePie page={page} actuals={pageActuals}></PagePie>
        </StyledPresent>
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};

export default Present;
