import styled from "styled-components";

export const StyledFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  & #navlogo {
    width: 1.5rem;
  }

  & :hover {
    transition: color 0.3s linear;
    color: ${({ theme }) => theme.colors.dark};
  }
  .links {
    width: 20%;
    font-size: 1rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.middle}a6;
  }

  .icon-button {
    cursor: pointer;
    padding: 0;
    color: ${({ theme }) => theme.colors.middle};
  }
  #to-top-icon {
    width: 1rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    .links {
      font-size: 1.5rem;
    }
  }
`;
