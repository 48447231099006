import React, { useState, useContext } from "react";

export const ModalContext = React.createContext(null);

export function useModalContext() {
  return useContext(ModalContext);
}

export function ModalProvider(props) {
  // console.log("---Rerendered Modal Context");

  const [modal, setModal] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        modal: modal,
        actions: {
          setModal: setModal,
        },
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
}

export const Consumer = ModalContext.Consumer;
