import React from "react";

import { Menu } from "./Menu";


export function DrawerSwitch({ mode }) {
  switch (mode) {
    
    case "menu":
      return <Menu />;
    default:
      return "No Matching Drawer Ref";
  }
}
