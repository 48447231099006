import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 1fr;
  transition: 0.3s all;
  width: 100%;

  & h3 {
    font-size: 1.5rem;
    font-weight: 100;
    letter-spacing: 0.5rem;
    margin-top: 0;
  }

  & input {
    font-size: 1.25rem;
    font-weight: 100;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    border-color: ${({ theme }) => theme.colors.middle}
      ${({ theme }) => theme.colors.dark} ${({ theme }) => theme.colors.dark}
      ${({ theme }) => theme.colors.middle};
    border-style: solid;
    border-width: 1px 1px 1px 10px;
    border-image: none 100% / 1 / 0 stretch;
    background: ${({ theme }) => theme.colors.middle}15 none repeat scroll 0%
      0%;
    color: ${({ theme }) => theme.colors.white};
    transition: 0.5s all;
    ::placeholder {
      color: ${({ theme }) => theme.colors.white}c4;
      font-weight: 100;
      font-size: 1.5rem;
    }
    :hover {
      background: ${({ theme }) => theme.colors.middle}35 none repeat scroll 0%
        0%;
    }
    /* &#Email {
      text-transform: lowercase;
    } */
  }

  & p.error {
    font-size: 1rem;
    align-self: flex-end;
    margin: 0 0 3px 0;
    color: ${({ theme }) => theme.colors.standout};
  }

  & #submit-section {
    margin: 1rem 0 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type="checkbox"] {
    font-size: 1rem;
    margin: 0 0 25px 10px;
    cursor: pointer;
    border: none;
  }

  div#developer {
    margin: 0 0 0.5rem 0;
    position: relative;
    left: 18px;
    max-width: 300px;
    /* justify-self: flex-start; */
    display: flex;
    flex-direction: column;
    /* align-content: flex-start; */
  }

  .fail {
    border-color: ${({ theme }) => theme.colors.standout}
      ${({ theme }) => theme.colors.dark} ${({ theme }) => theme.colors.dark}
      ${({ theme }) => theme.colors.standout};
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.standout}15;
  }
`;
