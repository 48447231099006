import styled from "styled-components";

export const StyledAbout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  text-align: center;

  transition: transform 0.3s ease-in-out;

  #title {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 25%;
    }
    & > h3 {
      font-size: 2rem;
      margin: 0 1rem;
      font-weight: 100;
      letter-spacing: 0.25rem;
    }
  }
  a:-webkit-any-link {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.standout};
  }
  .viewpolicy {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.standout};
  }
  #purpose {
    text-align: left;
  }
  h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
  & > #note {
    font-style: italic;
  }
`;
