import React from "react";
import { useHistory } from "react-router-dom";

import { Auth } from "aws-amplify";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

import { useDrawerContext, useAppContext } from "../../../../context";

import { StyledMenu } from "./Menu.styled";

export const Menu = () => {
  const history = useHistory();
  const contextDrawer = useDrawerContext();
  const context = useAppContext();

  return (
    <StyledMenu id="rpanel">
      {/* <img src={logo} id="logo" alt="logo" /> */}
      <div
        id="profile"
        onClick={() => {
          history.push("/account");
          contextDrawer.actions.setDrawer(false);
        }}
      >
        <IconButton className="user">
          <FontAwesomeIcon className="icons" icon={faUserCircle} />
        </IconButton>
        <span role="img" aria-label="account" id="account">
          edit profile
        </span>
      </div>

      <div
        onClick={() => {
          history.push("/past");
          contextDrawer.actions.setDrawer(false);
        }}
      >
        <span role="img" aria-label="past">
          past
        </span>
      </div>
      <div
        onClick={() => {
          history.push("/present");
          contextDrawer.actions.setDrawer(false);
        }}
      >
        <span role="img" aria-label="present">
          present
        </span>
      </div>
      <div
        onClick={() => {
          history.push("/future");
          contextDrawer.actions.setDrawer(false);
        }}
      >
        <span role="img" aria-label="future">
          future
        </span>
      </div>

      <div
        onClick={() => {
          history.push("/docs");
          contextDrawer.actions.setDrawer(false);
        }}
        id="docs"
      >
        <span role="img" aria-label="docs?">
          -quick start-
        </span>
      </div>

      <div
        id="signoff"
        href="/landing"
        onClick={() => {
          context.actions.setLoading(true);
          Auth.signOut();
          contextDrawer.actions.setDrawer(false);
        }}
      >
        <span role="img" aria-label="future">
          sign off
        </span>
      </div>
    </StyledMenu>
  );
};

export default Menu;
