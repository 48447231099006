import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from "@material-ui/icons/Replay";
import Popover from "@material-ui/core/Popover";

import { checkDataPoint } from "./checkDataPoint";
import { useListContext } from "../Context";

import { Chip } from "../../../common";
import { Filter } from "./Filter/Filter";
import { StyledFilters } from "./Filters.styled";

export function Filters() {
  const listContext = useListContext();

  const [popdown, setPopdown] = useState(false);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    // determine which filters are active
    var actives = listContext.filters.filter((filt) => filt["Active"]);
    // goahead and save those for reference
    listContext.actions.setFiltersActive(actives);
    // open the filters if we have any active
    if (actives.length) {
      listContext.actions.setFiltersOpen(true);
    }
    // set the active to the front of the filters bar
    var inactives = listContext.filters.filter((filt) => !filt["Active"]);
    setFilters(actives.concat(inactives));

    // apply these active filters
    if (actives.length) {
      var newFiltered = listContext.data;
      // console.log(newFiltered.length);
      actives.forEach(function (filt) {
        newFiltered = newFiltered.filter(checkDataPoint, filt);
        // console.log(newFiltered.length);
      });
      // get just the gameids
      listContext.actions.setFilterResult(newFiltered.map((g) => g["id"]));
    } else {
      // if no filters set it to false
      listContext.actions.setFilterResult(false);
    }
  }, [listContext.data, listContext.trigger]);

  const handleReset = () => {
    var filtersUpdated = filters;
    filtersUpdated.forEach(function (obj) {
      obj["Active"] = false;
    });
    listContext.actions.setFilters(filtersUpdated);
    listContext.actions.setTrigger(!listContext.trigger);
  };

  const handleDelete = (event, attr) => {
    var filtersUpdated = filters;
    filtersUpdated.forEach(function (obj) {
      if (obj["Attribute"] === attr) {
        obj["Active"] = false;
      }
    });
    listContext.actions.setFilters(filtersUpdated);
    listContext.actions.setTrigger(!listContext.trigger);
    event.stopPropagation();
  };

  const handleClick = (event) => {
    setPopdown(event.currentTarget.id);
  };

  return (
    <StyledFilters id="filters" filtersOpen={listContext.filtersOpen}>
      <IconButton id="reset" aria-label="reset" onClick={handleReset}>
        <ReplayIcon id="to-top-icon" />
      </IconButton>
      <div id="chips">
        {filters.map(function (filter, index) {
          var text = "";

          if (filter["Active"]) {
            text = JSON.stringify(filter["Value"])
              .replace("[", "")
              .replace(",", "-")
              .replace("]", "");
          } else {
            text = filter["Attribute"];
          }
          return (
            <Chip
              icon={filter["Icon"]}
              text={text}
              key={index}
              id={filter["Attribute"]}
              active={filter["Active"]}
              handleDelete={handleDelete}
              handleClick={handleClick}
            />
          );
        })}
      </div>
      <Popover
        id="popdown-wrapper"
        open={popdown ? true : false}
        anchorEl={document.getElementById(popdown)}
        onClose={() => setPopdown(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Filter popdown={popdown} setPopdown={setPopdown} />
      </Popover>
    </StyledFilters>
  );
}
