import React from "react";

import { StyledTerms } from "./Terms.styled";

const Terms = () => {
  return (
    <StyledTerms id="content">
      <p>This will be the Terms page</p>
    </StyledTerms>
  );
};

export default Terms;
