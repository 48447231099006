import React from "react";

import CancelIcon from "@material-ui/icons/Cancel";
import { StyledChip } from "./Chip.styled";

export function Chip({ id, icon, active, text, handleClick, handleDelete }) {
  return (
    <StyledChip id={id} active={active} onClick={handleClick}>
      <span id="icon">{icon}</span>
      <span id="text">{text}</span>
      {handleDelete && active && (
        <CancelIcon id="delete" onClick={(e) => handleDelete(e, id)} />
      )}
    </StyledChip>
  );
}
