import styled from "styled-components";

export const StyledDrawer = styled.div`
  /* animation: createPage 0.5s; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.9s all;

  & .icons {
    font-size: 2rem;
    & :hover {
      transition: color 0.3s linear;
      color: ${({ theme }) => theme.colors.standout};
    }
  }
`;

