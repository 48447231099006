import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { useAppContext } from "../../../../../context";

import { Button, Loader } from "../../../../common";
import { AWSForgotPassword } from "../scripts";
import { StyledForm } from "./Form.styled";

export const NewPwdForm = ({ handleNewPwd }) => {
  const context = useAppContext();

  return (
    <Formik
      initialValues={{
        Code: "",
        NewPassword: "",
        RetypePassword: "",
      }}
      validationSchema={Yup.object({
        Code: Yup.number().required("required"),
        NewPassword: Yup.string()
          .required("required")
          .min(8, "need 8 char")
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
            "needs an upper, lower, & number"
          ),
        RetypePassword: Yup.string()
          .required("required")
          .oneOf([Yup.ref("NewPassword")], "passwords must match"),
        //   .notOneOf([Yup.ref("OldPassword")], "gotta be a new one"),
        // OldPassword: Yup.string()
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let success = await AWSForgotPassword(
          context.user,
          values,
          handleNewPwd
        );
        if (!success) {
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <StyledForm id="NewPwdForm" onSubmit={formik.handleSubmit}>
          {formik.touched.Code && formik.errors.Code && (
            <p className="error">{formik.errors.Code}</p>
          )}
          <input
            className={formik.errors.Code ? "fail" : "success"}
            type="text"
            placeholder="Code"
            autoComplete="off"
            name="Code"
            id="Code"
            value={formik.values.Code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.NewPassword && formik.errors.NewPassword && (
            <p className="error">{formik.errors.NewPassword}</p>
          )}
          <input
            className={formik.errors.NewPassword ? "fail" : "success"}
            type="password"
            placeholder="NewPassword"
            autoComplete="on"
            name="NewPassword"
            id="NewPassword"
            value={formik.values.NewPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.RetypePassword && formik.errors.RetypePassword && (
            <p className="error">{formik.errors.RetypePassword}</p>
          )}
          <input
            className={formik.errors.RetypePassword ? "fail" : "success"}
            type="password"
            placeholder="RetypePassword"
            autoComplete="off"
            name="RetypePassword"
            id="RetypePassword"
            value={formik.values.RetypePassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Button
            id="submit"
            type="submit"
            content={formik.isSubmitting ? "hmmm..." : "Make a Change!"}
            className={formik.isSubmitting ? "loading" : ""}
            disabled={formik.isSubmitting}
          />
          {formik.isSubmitting && <Loader />}
        </StyledForm>
      )}
    </Formik>
  );
};
