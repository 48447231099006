import axios from "axios";

/**
 * General function to query our database Get api
 * @param  {Object} body The body of the query
 * @return {Array}       The result of the querym usually an array
 * @throws {Error}      Throws an error up from its catch block
 */
export async function _Get(body) {
  try {
    console.log("Func: Get");
    var url;
    var key;
    if (process.env.NODE_ENV === "development") {
      key = process.env.REACT_APP_LUMINOTE_XAPIKEY_DEV;
      url = "http://localhost:3001/dev/get";
    } else {
      key = process.env.REACT_APP_LUMINOTE_XAPIKEY;
      url = "https://ah1m82mw33.execute-api.us-east-2.amazonaws.com/dev/get";
    }

    const heads = {
      "Content-Type": "application/json",
      Authorization: "",
      "x-api-key": key,
    };

    // make axios call, returns the result (like py requests)
    var resp = await axios({
      method: "post",
      url: url,
      headers: heads,
      data: body,
    });
    if (resp.data.status !== 200) {
      throw resp.data;
    }
    console.info(`SUCCESS in func Get: ${resp.data?.message}`);
    return resp.data;
  } catch (err) {
    console.error(`ERROR in func Get: ${err}`);
    throw err; //throw it further up the chain
  }
}
