import styled from "styled-components";

export const StyledMenu = styled.div`
  width: 200px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: right;
  text-align: right;

  & div {
    flex: 1;
    font-size: 1.5rem;
    text-transform: lowercase;
    padding: 1rem 0;
    font-weight: lighter;
    letter-spacing: 0.3rem;
    color: ${({ theme }) => theme.colors.dark};
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: center;

    &:hover {
      color: ${({ theme }) => theme.colors.standout};
    }
  }

  & #user {
    flex: 3;
    max-width: 125px;
    max-height: 125px;
    margin: 1rem auto 0 auto;
  }

  #profile {
    display: flex;
    flex-direction: column;
    margin-bottom: 5vh;
  }
#account {
    font-size: 1rem;
  }
  #signoff{
    font-size: 1.25rem;
    font-weight: 400;
  }

  & .icons{
    font-size: 100px;
    color: ${({ theme }) => theme.colors.dark};
  }
  #docs{
    margin-top: 2rem;
    font-size: 1.25rem;
    vertical-align: bottom;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: ${({ theme }) => theme.tabletWidth}) {
    width: 375px;

    & & div {
      letter-spacing: 0.5rem;
    }
  }
  @media (max-height: ${({ theme }) =>
      theme.tabletWidth}) and (orientation: landscape) {
    padding: 0.5rem;
    justify-content: stretch !important;
    & > * {
      flex: 1;
    }
    & div {
      position: relative;
      top: -20px;
      font-size: 1.25rem;
      flex-wrap: wrap;
      padding: 1rem 0;
    }
    & #closePanel {
      position: relative;
      top: 10px;
      left: 5px;
      flex: 0.5;
    }
    & #logo {
      display: none;
    }
  }
`;
