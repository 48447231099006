import React from "react";
import Loader from "react-loader-spinner";

import { theme } from "../../../theme";
import { StyledLoader } from "./Loader.styled";

export default function Load({ id, color, height, width, mode }) {
  return (
    <StyledLoader id={id}>
      <Loader type={mode} color={color} height={height} width={width} />
    </StyledLoader>
  );
}

Load.defaultProps = {
  id: "loader",
  color: theme.colors.middle,
  height: "30px",
  width: "30px",
  mode: "Puff",
};
