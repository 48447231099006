import React, { useState, useContext, useEffect } from "react";

export const ListContext = React.createContext(null);

export function useListContext() {
  return useContext(ListContext);
}

export function ListProvider({ data, initFilters, options, children }) {
  // used to store the raw data sent to this List component
  const [fullData, setFullData] = useState(data);
  const [listOptions, setListOptions] = useState([]);

  useEffect(() => {
    setFullData(data);
    setListOptions(options);
  }, [data, options]);

  // ----used for saving position in scroll upon return to Browse
  // denotes the number of datapoints loaded from the infinate scroll
  const [dataLoaded, setDataLoaded] = useState(10);

  // the cards that are marked for mutliselect, to do bulk edits
  // const [multis, setMultis] = useState([]);
  // denotes the windwo scroll position
  // const [browseScroll, setBrowseScroll] = useState(0);

  // ----used for search
  // the text user last searcehd for
  const [searchText, setSearchText] = useState("");
  // the index of all the resulting records
  const [searchResult, setSearchResult] = useState(false);

  // ----used for filters
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState(initFilters || []);
  const [filtersActive, setFiltersActive] = useState([]);
  const [filterResult, setFilterResult] = useState(false);

  const [dataLimited, setDataLimited] = useState([]);

  const [trigger, setTrigger] = useState(false);

  return (
    <ListContext.Provider
      value={{
        fullData: fullData,
        listOptions: listOptions,
        // multis: multis,
        dataLoaded: dataLoaded, //count
        // browseScroll: browseScroll, // position
        filterResult: filterResult, //gameIds from filter
        searchResult: searchResult, // gameIds from search
        searchText: searchText, // search bar value
        filtersOpen: filtersOpen,
        filters: filters, // fiters on browse
        filtersActive: filtersActive, // count of active filters
        trigger: trigger,
        dataLimited: dataLimited,
        actions: {
          setFullData: setFullData,
          setListOptions: setListOptions,
          setDataLoaded: setDataLoaded,
          // setMultis: setMultis,
          // setBrowseScroll: setBrowseScroll,
          setFiltersOpen: setFiltersOpen,
          setFilters: setFilters,
          setFiltersActive: setFiltersActive,
          setFilterResult: setFilterResult,
          setSearchResult: setSearchResult,
          setSearchText: setSearchText,
          setTrigger: setTrigger,
          setDataLimited: setDataLimited,
        },
      }}
    >
      {children}
    </ListContext.Provider>
  );
}

export const Consumer = ListContext.Consumer;
