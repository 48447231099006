import styled from "styled-components";

export const StyledValidate = styled.div`
  transition: 0.3s all;
  width: 100%;
  & h3 {
    font-size: 1.5rem;
    font-weight: 100;
    letter-spacing: 0.5rem;
    margin-top: 0;
  }
`;
