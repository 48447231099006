import styled from "styled-components";

export const StyledCard = styled.div`
  display: grid;
  grid-template-columns: 83% 7% 10%;
  grid-template-rows: 1fr 1fr;
  /* grid-template-rows: ${(props) => `${props?.height / 2}px` ?? "25px"} ${(
    props
  ) => `${props?.height / 2}px` ?? "25px"}; */
  grid-template-areas:
    "top notefl action"
    "btm alertfl multi";
  height: ${(props) => `${props?.height}px` ?? "50px"};
  max-height: ${(props) => `${props?.height}px` ?? "50px"};
  margin: 0;
  box-sizing: border-box;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.dark};
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  margin: 0.25rem;
  box-shadow: 0 4px 8px 0 ${({ theme }) => theme.colors.grey}54,
    0 6px 20px 0 ${({ theme }) => theme.colors.grey}54;
  padding: 0 0.25rem;

  .rows {
    display: flex;
    justify-content: flex-start;
    /* margin: 0 0.25rem; */
    padding: 0 0.25rem;
  }
  #top {
    grid-area: top;
  }
  #desciption {
    max-width: 70%;
  }
  #btm {
    grid-area: btm;
  }
  #notefl {
    grid-area: notefl;
  }
  #validatefl {
    grid-area: alertfl;
  }
  #multi {
    grid-area: multi;
  }
  #action {
    grid-area: action;
    display: flex;
    align-items: center;
  }
  #date * {
    font-weight: 100;
  }
  .green {
    color: ${({ theme }) => theme.colors.credit};
  }
  .dark {
    color: ${({ theme }) => theme.colors.dark}2e;
  }
  .standout {
    color: ${({ theme }) => theme.colors.standout};
  }

  @media all and (min-width: ${({ theme }) => theme.tabletWidth}) {
    /* min-width: 350px */
  }
`;
