const colorMaps = {
  ryg: [
    { pct: 0.0, color: { r: 199, g: 60, b: 46 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 0.95, color: { r: 50, g: 129, b: 69 } },
  ],
  gyr: [
    { pct: 0.0, color: { r: 50, g: 129, b: 69 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 0.95, color: { r: 199, g: 60, b: 46 } },
  ],
  blue: [
    { pct: 0.0, color: { r: 130, g: 130, b: 130 } },
    // { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0, g: 149, b: 225 } },
  ],
};

export const getColorFromValue = function (pct, scale) {
  var percentColors = colorMaps["ryg"]; //default
  if (scale) {
    if (typeof scale === "string") {
      percentColors = colorMaps[scale];
    } else {
      percentColors = scale;
    }
  }

  for (var i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  var lower = percentColors[i - 1];
  var upper = percentColors[i];
  var range = upper.pct - lower.pct;
  var rangePct = (pct - lower.pct) / range;
  var pctLower = 1 - rangePct;
  var pctUpper = rangePct;
  var color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };
  return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
};
