import React, { useEffect } from "react";
// import { useHistory } from "react-router-dom";

import {
  useAppContext,
  useSnackContext,
  useModalContext,
} from "../../../../context";
import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import { StyledAccount } from "./Account.styled";
import { Refresh as _Refresh } from "../../../../scripts/Member/Refresh";
import { Sync } from "../../../../scripts/User/Sync";
import { GetActuals } from "../../../../scripts/Actuals/GetActuals";

const Account = () => {
  // const history = useHistory();
  const context = useAppContext();
  const contextModal = useModalContext();
  const contextSnack = useSnackContext();

  // useEffect(() => {
  //   console.log(context.user)
  //   if (context.user) {
  //     history.push("/landing");
  //   }
  // });

  function handleRefresh(member) {
    console.log(member);
    _Refresh({ mx_guid: member.user_guid, member_guid: member.guid })
      .then((result) => {
        console.log(result);
        if (result.status == 200) {
          contextSnack.actions.setSnackMessage(result.message);
          contextSnack.actions.setSnack("success");
        }
        Sync({ user_guid: context.user.UserId });
        GetActuals(context.user.MX.guid, null, context.actions.setActuals);
      })
      .catch(() => {
        contextSnack.actions.setSnackMessage("Sync failed");
        contextSnack.actions.setSnack("error");
      });
  }

  return (
    <StyledAccount id="content">
      {context.user && (
        <div id="info">
          <h3>
            {context.user.UserName
              ? context.user.UserName
              : context.user.UserId.slice(0, 10)}
          </h3>
          <p className="detail">{JSON.stringify(context.user.UserId)}</p>
        </div>
      )}
      {context.user && (
        <div id="inst">
          <h4>Your Institutions</h4>
          {context.user.MX.Members &&
            context.user.MX.Members.map((mi) => {
              return (
                <div className="card">
                  <p className="detail">{JSON.stringify(mi.name)}</p>
                  <p className="detail">
                    {JSON.stringify(mi.successfully_aggregated_at)}
                  </p>
                  <button onClick={() => handleRefresh(mi)}>Refresh</button>
                </div>
              );
            })}
          <IconButton
            className="addbutton"
            onClick={() => {
              contextModal.actions.setModal("connect");
            }}
          >
            <FontAwesomeIcon className="icons" icon={faPlusCircle} />
          </IconButton>
        </div>
      )}
      {!context.user && <div>Login</div>}
    </StyledAccount>
  );
};

export default Account;
