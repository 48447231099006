import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useModalContext, useAppContext } from "../../../../../../../context";

import { format } from "date-fns";
import { HiDotsVertical } from "react-icons/hi";
import { ImArrowUpLeft2, ImArrowDownRight2 } from "react-icons/im";
import { RiStickyNoteFill } from "react-icons/ri";
import { FaCheckDouble } from "react-icons/fa";

import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";

import CardActionArea from "@material-ui/core/CardActionArea";
// import CardMedia from "@material-ui/core/CardMedia";
import { UpsertActual } from "../../../../../../../scripts/Actuals/UpsertActuals";

import { Tag } from "../../../../../../common";
import { Edit } from "./Edit";
import { StyledCard } from "./Card.styled";

export function ActualCard({
  data,
  height,
  selected,
  handleSelected,
  // overlay,
}) {
  const modalContext = useModalContext();
  const context = useAppContext();

  const [valid, setValid] = useState(false);

  // just check if this record is vaidlated already
  useEffect(() => {
    setValid(data?._IsValidated || false);
  }, [data]);

  const onActionClick = (e) => {
    modalContext.actions.setModal(<Edit data={data}></Edit>);
  };

  const onNoteClick = (e) => {
    // TODO: build Note module
    modalContext.actions.setModal(<Edit data={data}></Edit>);
  };

  const onValidateClick = async (e) => {
    var setto = !valid;
    setValid(!valid);
    // fire off update to db and to data
    const query = { user_guid: data.mx_guid, guid: data.guid };
    const update = { _IsValidated: setto };
    let resp_u = await UpsertActual(query, update);

    if (resp_u === true) {
      console.info("Validated!");
    } else {
      console.error(resp_u);
    }
    context.actions.updateData("actuals", { ...update, _id: data._id });
  };

  const onSelected = (e) => {
    // TODO: build Note module
    // setIsSelected(!isSelected);
    handleSelected(data?._id);
  };

  return (
    <StyledCard height={height}>
      {data ? (
        <React.Fragment>
          <div id="top" className="rows">
            <Tag
              id="creditDebit"
              content={
                data?._type === "CREDIT" ? (
                  <ImArrowDownRight2 className="green icon" />
                ) : (
                  <ImArrowUpLeft2 className="dark icon" />
                )
              }
              isIcon={true}
              border={false}
            />
            <Tag
              id="amount"
              content={`$${data?._amount.toFixed(2)}`}
              border={false}
            />

            <Tag id="desciption" content={data._description} border={false} />
          </div>
          <div id="btm" className="rows">
            <Tag
              id="date"
              content={format(Date.parse(data.posted_at), "Pp")}
              border={false}
            />
          </div>

          {data?.Note && (
            <Tag
              id="notefl"
              content={<RiStickyNoteFill className="standout icon" />}
              border={false}
              isIcon={true}
              onClick={onNoteClick}
            />
          )}

          <Tag
            id="validatefl"
            content={
              <FaCheckDouble className={`${valid ? "green" : "dark"} icon`} />
            }
            border={false}
            isIcon={true}
            onClick={onValidateClick}
          />
          <Tag
            id="multi"
            content={
              selected ? (
                <MdRadioButtonChecked className={`icon`} />
              ) : (
                <MdRadioButtonUnchecked className={`icon`} />
              )
            }
            border={false}
            isIcon={true}
            onClick={onSelected}
          />

          <CardActionArea id="action" component="div" onClick={onActionClick}>
            <HiDotsVertical />
          </CardActionArea>
          {/* {overlay && !selected && <div id="overlay">over</div>} */}
        </React.Fragment>
      ) : (
        <div id="filler" />
      )}
    </StyledCard>
  );
}

// ActualCard.defaultProps = {
//   data: {},
//   height: 50,
//   selected: false,
//   overlay: false,
// };
