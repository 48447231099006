import axios from "axios";
import { Sync } from "../User/Sync";

export async function SignIn(data, token, setUser, setLoading) {
  console.log("SignIn API");
  setLoading(true);
  var url;
  var key;
  if (process.env.NODE_ENV === "development") {
    key = process.env.REACT_APP_LUMINOTE_XAPIKEY_DEV;
    url = "http://localhost:3001/dev/user/signin";
  } else {
    key = process.env.REACT_APP_LUMINOTE_XAPIKEY;
    url =
      "https://ah1m82mw33.execute-api.us-east-2.amazonaws.com/dev/user/signin";
  }

  const heads = {
    "Content-Type": "application/json",
    Authorization: token,
    "x-api-key": key,
  };
  const params = { UserId: data.attributes.sub };

  await axios({
    method: "post",
    url: url,
    headers: heads,
    params: params,
    data: data.attributes,
  })
    .then(function (response) {
      setUser(response["data"]["data"]);
      setLoading(false);
      // trigger a sync of all user data from MX
      Sync({ user_guid: response.data.data.UserId });
      return true;
    })
    .catch(function (error) {
      setUser(null);
      setLoading(false);
      return false;
    });
}
