export const initFilters = [
  {
    Attribute: "Strategy",
    Label: "% Strategy",
    Icon: "♟️",
    Type: "range",
    Active: false,
    Value: [0, 100],
    Step: 5,
    Min: 0,
    Max: 100,
  },
  {
    Attribute: "Memory",
    Label: "% Memory",
    Icon: "💭",
    Type: "range",
    Active: false,
    Value: [0, 100],
    Step: 5,
    Min: 0,
    Max: 100,
  },
  {
    Attribute: "Luck",
    Label: "% Luck",
    Icon: "🍀",
    Type: "range",
    Active: false,
    Value: [0, 100],
    Step: 5,
    Min: 0,
    Max: 100,
  },
  {
    Attribute: "Speed",
    Label: "% Speed",
    Icon: "🏎️",
    Type: "range",
    Active: false,
    Value: [0, 100],
    Step: 5,
    Min: 0,
    Max: 100,
  },
  {
    Attribute: "Social",
    Label: "% Social",
    Icon: "🤝",
    Type: "range",
    Active: false,
    Value: [0, 100],
    Step: 5,
    Min: 0,
    Max: 100,
  },
  {
    Attribute: "Complexity",
    Label: "Complexity Rating",
    Icon: "❓",
    Type: "range",
    Active: false,
    Value: [0, 10],
    Step: 1,
    Min: 0,
    Max: 10,
  },
  {
    Attribute: "Replay",
    Label: "Variation",
    Icon: "♻️",
    Type: "range",
    Active: false,
    Value: [0, 10],
    Step: 1,
    Min: 0,
    Max: 10,
  },
  {
    Attribute: "Duration",
    Label: "Duration (hrs)",
    Icon: "🕒",
    Type: "range",
    Active: false,
    Value: [0, 12],
    Step: 0.5,
    Min: 0,
    Max: 12,
  },
  {
    Attribute: "Players",
    Label: "Players",
    Icon: "👥",
    Type: "range",
    Active: false,
    Value: [0, 20],
    Step: 1,
    Min: 0,
    Max: 20,
  },
  {
    Attribute: "Rank",
    Label: "Rank",
    Icon: "🏆",
    Type: "range",
    Active: false,
    Value: [0, 10000],
    Step: 10,
    Min: 0,
    Max: 10000,
  },
  {
    Attribute: "Rating",
    Label: "BGA Rating",
    Icon: "⭐",
    Type: "range",
    Active: false,
    Value: [0, 5],
    Step: 0.1,
    Min: 0,
    Max: 5,
  },
  {
    Attribute: "Hot",
    Label: "Is Hot?",
    Icon: "🔥",
    Type: "bool",
    Active: false,
    Value: 0,
    Step: 1,
    Min: -1,
    Max: 1,
  },
  {
    Attribute: "Shelf",
    Label: "In Shelf?",
    Icon: "🗹",
    Type: "bool",
    Active: false,
    Value: 0,
    Step: 1,
    Min: -1,
    Max: 1,
  },
  {
    Attribute: "Liked",
    Label: "Liked?",
    Icon: "🤍",
    Type: "bool",
    Active: false,
    Value: 0,
    Step: 1,
    Min: -1,
    Max: 1,
  },
];
