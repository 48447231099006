import styled from "styled-components";

export const StyledList = styled.div`
  animation: createPage 1s;
  /* max-height: 100%; */
  margin: 0.5rem;
  /* padding: 1rem; */
  background-color: ${({ theme }) => theme.colors.white}c4;
  border-radius: 0.5rem;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.middle}53;
  border-width: 1px;
  color: ${({ theme }) => theme.colors.middle};
  font-weight: 300;
  height: 100%;
  overflow: hidden;

  @keyframes createPage {
    from {
      /* max-height: 0; */
      opacity: 0;
    }
    to {
      /* max-height: inherit; */
      opacity: 1;
    }
  }
`;
