import styled from "styled-components";

export const StyledNavBar = styled.div`
  padding: 1.25rem 0.25rem;
  display: grid;
  /* position: ${(props) => (props.sticky ? "sticky" : "relative")}; */
  width: 100%;
  /* top: 0; */
  grid-template-areas: "land journal edit check aspire account";
  /* justify-content: space-evenly; */
  /* align-items: center; */

  & #navlogo {
    width: 2rem;
  }
  #name {
    position: relative;
    left: -15px;
    font-size: 1.3rem;
    text-shadow: none;
    margin: 0.25rem;
  }
  .icons {
    font-size: 2rem;
    & :hover {
      transition: color 0.3s linear;
      color: ${({ theme }) => theme.colors.dark};
    }
  }

  #userIcon {
    grid-area: account;
    padding: 0;
  }

  .icon-button,
  #loader {
    cursor: pointer;
    padding: 0;
    color: ${({ theme }) => theme.colors.white};
    :hover {
      color: ${({ theme }) => theme.colors.standout};
      opacity: 0.8;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    .item {
      font-size: 1.5rem;
    }
  }
`;
