import React, { useState, useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { NavBar } from "../principle/NavBar";
import { Footer } from "../principle/Footer";
import { Page } from "../principle/Page";
import { SnackWrapper } from "../principle/Snack";
import { ModalWrapper } from "../principle/Modal/Wrapper";
import { DrawerWrapper } from "../principle/Drawer/Wrapper";


import { useOnClickOutside } from "../../hooks";
import { StyledWrapper } from "./Wrapper.styled";

function Wrapper() {
  const [rOpen, setrOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setrOpen(false));

  return (
    <StyledWrapper>
      <Router>
        <NavBar rOpen={rOpen} setrOpen={setrOpen} />
        <Page />
        <Footer />
        <ModalWrapper />
        <DrawerWrapper />
        <SnackWrapper />
      </Router>
    </StyledWrapper>
  );
}


export default Wrapper;
