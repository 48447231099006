import { Auth } from "aws-amplify";

export async function AWSForgotPassword(username, handleResp) {
  try {
    var resp = await Auth.forgotPassword(username);
    handleResp(username, "success");
  } catch (error) {
    console.log("error signing out: ", error);
    handleResp(error, "error");
  }
}
