import styled from "styled-components";

export const StyledChip = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  /* border: 2px solid ${({ theme }) => theme.colors.light}; */
  margin: 0 0.25rem;
  border-radius: 2rem;
  padding: 0.1rem;
  font-size: 0.9rem;
  background: ${({ theme }) => theme.colors.light};
  box-shadow: 1px 1px 1px ${({ theme }) => theme.colors.white}54;
  transition: 0.15s all;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: ${(props) => (props.active ? 0.8 : 0.33)};

  #icon {
    width: 25px;
    height: 25px;
    background: none;
  }
  #text {
    flex: 1;
    width: max-content;
    margin-right: 0.25rem;
  }
  #delete {
    justify-self: flex-end;
    width: 20px;
    color: ${({ theme }) => theme.colors.dark};
  }

  &:hover {
    /* background: ${({ theme }) => theme.colors.middle}; */
    color: ${({ theme }) => theme.colors.dark};
    box-shadow: 1px 1px 4px ${({ theme }) => theme.colors.dark};
    cursor: pointer;
  }
  &:active {
    /* transform: translateY(1px); */
    border: 1px solid ${({ theme }) => theme.colors.white};
    opacity: 0.9;
  }
`;
