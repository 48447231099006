import React from "react";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { StyledDrawer } from "./Drawer.styled";

export function Drawer({ children, dismiss }) {

  return (
    <React.Fragment>
      {children ? (
        <StyledDrawer>
          <SwipeableDrawer anchor="right" open={children!==false}
          onClose={dismiss} transitionDuration={500}>
            <div >
              <IconButton className="chevron" onClick={dismiss}>
                <FontAwesomeIcon className="icons" icon={faChevronRight} />
              </IconButton>
              {children}
            </div>
          </SwipeableDrawer>
        </StyledDrawer>
      ) : null}
    </React.Fragment>
  );
}
