import React, { useState, useContext } from "react";

export const DrawerContext = React.createContext(null);

export function useDrawerContext() {
  return useContext(DrawerContext);
}

export function DrawerProvider(props) {
  const [drawer, setDrawer] = useState(false);

  return (
    <DrawerContext.Provider
      value={{
        drawer: drawer,
        actions: {
          setDrawer: setDrawer,
        },
      }}
    >
      {props.children}
    </DrawerContext.Provider>
  );
}

export const Consumer = DrawerContext.Consumer;
