import styled from "styled-components";

export const StyledNewPwd = styled.div`
  #heading {
    margin-top: 0;
    text-align: center;
  }
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: 0 25%;
    margin-bottom: 1rem;
  }
`;
