import React from "react";

import { StyledModal, ModalBoxSetup, ModalBackground } from "./Modal.styled";

export function Modal({ children, dismiss }) {
  return (
    <React.Fragment>
      {children ? (
        <StyledModal>
          <ModalBoxSetup>{children}</ModalBoxSetup>
          <ModalBackground onClick={dismiss} />
        </StyledModal>
      ) : null}
    </React.Fragment>
  );
}
