import { _Get } from "../Get";

/**
 * Function to get a users Actual lines from the db
 * @param  {String} mx_guid The users MX guid
 * @param {Integer} page optional, to only pull for a specific page
 * @throws {String}      console.error the error
 */
export async function GetActuals(mx_guid, page = null, setActuals) {
  try {
    console.log("Func: GetActuals");
    const body = {
      collection: "Actual",
      query: { user_guid: mx_guid },
      proj: [
        "account_guid",
        "amount",
        "posted_at",
        "type",
        "guid",
        "status",
        "top_level_category",
        "description",
        "member_guid",
        "original_description",
        "Page",
        "Note",
        "_IsValidated",
        "_account_guid",
        "_amount",
        "_posted_at",
        "_type",
        "_description",
      ],
    };
    if (page) {
      body["query"]["Page"] = page;
    }

    const actuals = await _Get(body);

    console.info("Success: GetActuals");
    setActuals(actuals.data);
  } catch (err) {
    console.error(`FAILED func GetActuals: ${err}`);
  }
}
