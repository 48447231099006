import React, { useEffect } from "react";
import { StyledPrivacy } from "./Privacy.styled";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return function saveSpot() {
      window.scrollTo(0, 0);
    };
  }, []);
  let policy = `<style>
    [data-custom-class='body'],
    [data-custom-class='body'] * {
        background: transparent !important;
    }
    
    [data-custom-class='title'],
    [data-custom-class='title'] * {
        font-family: Arial !important;
        font-size: 26px !important;
        color: #000000 !important;
    }
    
    [data-custom-class='subtitle'],
    [data-custom-class='subtitle'] * {
        font-family: Arial !important;
        color: #595959 !important;
        font-size: 14px !important;
    }
    
    [data-custom-class='heading_1'],
    [data-custom-class='heading_1'] * {
        font-family: Arial !important;
        font-size: 19px !important;
        color: #000000 !important;
    }
    
    [data-custom-class='heading_2'],
    [data-custom-class='heading_2'] * {
        font-family: Arial !important;
        font-size: 17px !important;
        color: #000000 !important;
    }
    
    [data-custom-class='body_text'],
    [data-custom-class='body_text'] * {
        color: #595959 !important;
        font-size: 14px !important;
        font-family: Arial !important;
    }
    
    [data-custom-class='link'],
    [data-custom-class='link'] * {
        color: #3030F1 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        word-break: break-word !important;
    }
</style>

<div data-custom-class="body">
    <div align="center" class="MsoNormal" data-custom-class="title" style="text-align: left; line-height: 1.5;">
        <strong><span style="line-height: 22.5px; font-size: 26px;">PRIVACY NOTICE</span></strong></div>
    <p style="font-size:15px;"><span style="color: rgb(127, 127, 127);"><strong><span data-custom-class="subtitle">Last
                    updated <bdt class="question">August 25, 2020</bdt></span></strong>
        </span>
    </p>
    <p style="font-size: 15px; line-height: 1.5;"><br></p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">Thank you for
                choosing to be part of our community at <bdt class="question">Splintered Glass Solutions LLC</bdt>
                <bdt class="block-component"></bdt> (“<bdt class="block-component"></bdt><strong>Company</strong>
                <bdt class="statement-end-if-in-editor"></bdt>”, “<strong>we</strong>”, “<strong>us</strong>”, or
                “<strong>our</strong>”). We are committed to protecting your personal information and your right to
                privacy. If you have any questions or concerns about this privacy notice, or our practices with regards
                to your personal information, please contact us at <bdt class="question">
                    support@splinteredglass.solutions</bdt>.
            </span></span>
    </p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">When you <bdt
                    class="block-component"></bdt>visit our website <bdt class="question"><a
                        href="https://www.gamegeneie.com/" target="_blank"
                        data-custom-class="link">https://www.gamegeneie.com/</a></bdt> (the "<strong>Website</strong>"),
                <bdt class="statement-end-if-in-editor"></bdt>
                <bdt class="block-component"></bdt>
                <bdt class="block-component"></bdt> and more generally, use any of our services (the
                "<strong>Services</strong>", which include the <span style="color: rgb(89, 89, 89);"><span
                        data-custom-class="body_text">
                        <bdt class="block-component"></bdt>Website<span style="color: rgb(89, 89, 89);"><span
                                data-custom-class="body_text">
                                <bdt class="statement-end-if-in-editor"></bdt>
                            </span></span>
        <bdt class="block-component"></bdt>
        </span>
        </span>
        </span><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                        data-custom-class="body_text">
                        <bdt class="block-component"></bdt>
                    </span></span>
        </span><span data-custom-class="body_text">), we appreciate that you are trusting us
                with your personal information. We take your privacy very seriously. In this privacy notice, we seek to
                explain to you in the clearest way possible what information we collect, how we use it and what rights
                you have in relation to it. We hope you take some time to read through it carefully, as it is important.
                If there are any terms in this privacy notice<span style="color: rgb(89, 89, 89);"> </span>that you do not agree with, please discontinue use of our Services immediately.</span>
        </span>
    </p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">This privacy
                notice applies to all information collected through our Services (which, as described above, includes
                our <bdt class="block-component"></bdt>Website<span style="color: rgb(89, 89, 89);"><span
                        data-custom-class="body_text">
                        <bdt class="statement-end-if-in-editor"></bdt>
                    </span></span>
        <bdt class="block-component">
            <bdt class="block-component"></bdt>
            </span>
            </span>
            </span><span data-custom-class="body_text">), as well as any related services, sales,
            marketing or events.</span></span>
    </p>
    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span data-custom-class="body_text">Please
                    read this privacy notice<span style="color: rgb(89, 89, 89);"> </span>carefully as it will help you understand what we do with the information that we collect.</span>
        </strong>
        </span>
    </p>
    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
    <p style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                        data-custom-class="heading_1">TABLE OF CONTENTS</span></span>
        </strong>
        </span>
    </p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#infocollect"><span style="color: rgb(89, 89, 89);">1.
                WHAT INFORMATION DO WE COLLECT?</span></a><span style="color: rgb(89, 89, 89);">
            <bdt class="block-component"></bdt>
        </span></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#infouse"><span style="color: rgb(89, 89, 89);">2. HOW
                DO WE USE YOUR INFORMATION?</span></a><span style="color: rgb(89, 89, 89);"><span
                style="color: rgb(89, 89, 89);">
                <bdt class="statement-end-if-in-editor"></bdt>
            </span></span>
    </p>
    <p style="font-size:15px;"><span style="color: rgb(89, 89, 89);"><a data-custom-class="link" href="#infoshare">3.
                WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
            <bdt class="block-component"></bdt>
        </span></span><span style="font-size: 15px; color: rgb(89, 89, 89);">
            <bdt class="block-component"></bdt>
        </span></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#cookies"><span
                style="font-size: 15px; color: rgb(89, 89, 89);">4. DO WE USE COOKIES AND OTHER TRACKING
                TECHNOLOGIES?</span></a><span style="font-size: 15px; color: rgb(89, 89, 89);">
            <bdt class="statement-end-if-in-editor"></bdt>
        </span><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">
                <bdt class="block-component"></bdt>
                <bdt class="block-component"></bdt>
            </span></span>
        </span>
    </p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#sociallogins"><span
                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                        style="color: rgb(89, 89, 89);">5. HOW DO WE HANDLE YOUR SOCIAL
                        LOGINS?</span></span></span></a><span style="color: rgb(89, 89, 89);"><span
                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">
                    <bdt class="statement-end-if-in-editor"></bdt>
                </span></span>
        <bdt class="block-component"></bdt>
        <bdt class="block-component"></bdt>
        </span>
    </p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#3pwebsites"><span style="color: rgb(89, 89, 89);">6.
                WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</span></a><span style="color: rgb(89, 89, 89);">
            <bdt class="statement-end-if-in-editor"></bdt>
        </span></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#inforetain"><span style="color: rgb(89, 89, 89);">7.
                HOW LONG DO WE KEEP YOUR INFORMATION?</span></a><span style="color: rgb(89, 89, 89);"><span
                style="color: rgb(89, 89, 89);">
                <bdt class="block-component"></bdt>
            </span></span>
    </p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#infosafe"><span style="color: rgb(89, 89, 89);">8. HOW
                DO WE KEEP YOUR INFORMATION SAFE?</span></a><span style="color: rgb(89, 89, 89);"><span
                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">
                    <bdt class="statement-end-if-in-editor"></bdt>
                </span></span>
        <bdt class="block-component"></bdt>
        </span>
    </p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#infominors"><span style="color: rgb(89, 89, 89);">9.
                DO WE COLLECT INFORMATION FROM MINORS?</span></a>
        <bdt class="statement-end-if-in-editor"></bdt>
    </p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#privacyrights"><span
                style="color: rgb(89, 89, 89);">10. WHAT ARE YOUR PRIVACY RIGHTS?</span></a></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#DNT"><span style="color: rgb(89, 89, 89);">11.
                CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#caresidents"><span style="color: rgb(89, 89, 89);">12.
                DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#policyupdates"><span
                style="color: rgb(89, 89, 89);">13. DO WE MAKE UPDATES TO THIS NOTICE?</span></a></p>
    <p style="font-size:15px;"><a data-custom-class="link" href="#contact"><span style="color: rgb(89, 89, 89);">14. HOW
                CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a></p>
    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
    <p id="infocollect" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
                    style="font-size: 19px;"><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE
                        COLLECT?</span></span>
        </strong>
        </span>
        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
    </p>
    <p style="font-size: 15px;"><strong><span style="font-size: 16px;"><span data-custom-class="heading_2"><strong><span
                            data-custom-class="heading_2"><br>Personal information you disclose to
                            us</span></strong></span>
        </span>
        </strong>
    </p>
    <p style="font-size:15px;"><strong><span data-custom-class="body_text"><em><span style="font-size: 15px;">In Short:
                         </span></em></span></strong><span data-custom-class="body_text"><em><span
                    style="font-size: 15px;">We collect information that you provide to us.</span></em>
        </span>
    </p>
    <p style="font-size:15px;"><span data-custom-class="body_text"><span style="font-size: 15px;">We collect personal
                information that you voluntarily provide to us when you <bdt class="block-component"></bdt>register on
                the <bdt class="block-component"></bdt>Website, <bdt class="statement-end-if-in-editor"></bdt>
                <bdt class="block-component"></bdt>
                <bdt class="block-component"></bdt><span data-custom-class="body_text"><span style="font-size: 15px;">
                        <bdt class="statement-end-if-in-editor"></bdt>
                    </span></span>express an interest in obtaining information about us or our products and Services, when you participate in activities on the <span data-custom-class="body_text"><span
                        style="font-size: 15px;">
                        <bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt>
                        <bdt class="block-component"></bdt>
                        <bdt class="block-component"></bdt>
                    </span></span>
        <bdt class="block-component"></bdt> (such as by posting messages in our online forums or entering competitions, contests or giveaways)
        <bdt class="statement-end-if-in-editor"></bdt> or otherwise when you contact us.
        </span>
        </span>
    </p>
    <p style="font-size:15px;"><span data-custom-class="body_text"><span style="font-size: 15px;">The personal
                information that we collect depends on the context of your interactions with us and the <span
                    data-custom-class="body_text">
                    <bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt>
                </span>, the choices you make and the products and features you use. The personal information we collect may include the following:
        <bdt class="block-component"></bdt>
        </span>
        </span>
    </p>
    <p style="font-size:15px;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Personal
                    Information Provided by You.</strong> We collect <bdt class="forloop-component"></bdt>
                <bdt class="question">names</bdt>; <bdt class="forloop-component"></bdt>
                <bdt class="question">email addresses</bdt>; <bdt class="forloop-component"></bdt>
                <bdt class="question">usernames</bdt>; <bdt class="forloop-component"></bdt>
                <bdt class="question">profile quiz results</bdt>; <bdt class="forloop-component"></bdt>and other similar
                information.
            </span><span data-custom-class="body_text">
                <bdt class="statement-end-if-in-editor"></bdt>
                <bdt class="block-component">
                    <bdt class="block-component"></bdt>
                </bdt>
            </span></span>
    </p>
    <p style="font-size:15px;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Social Media
                    Login Data.</strong> We may provide you with the option to register with us using your existing
                social media account details, like your Facebook, Twitter or other social media account. If you choose
                to register in this way, we will collect the information described in the section called "<span
                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><a data-custom-class="link"
                            href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>"
                        below.</span></span>
        </span>
        </span><span data-custom-class="body_text"><span
                style="font-size: 15px;">
                <bdt class="statement-end-if-in-editor">
                    <bdt class="statement-end-if-in-editor"></bdt>
                </bdt>
            </span></span>
    </p>
    <div><span data-custom-class="body_text">All personal information that you provide to us must be true, complete and
            accurate, and you must notify us of any changes to such personal information.<span style="font-size: 15px;">
                <bdt class="statement-end-if-in-editor">
                    <bdt class="statement-end-if-in-editor"></bdt>
                </bdt>
            </span></span>
        <bdt class="block-component"></bdt>
    </div>
    <p style="font-size: 15px;"><strong><span style="font-size: 16px;"><span data-custom-class="heading_2"><strong><span
                            data-custom-class="heading_2"><br>Information automatically
                            collected</span></strong></span>
        </span>
        </strong>
    </p>
    <div><span data-custom-class="body_text"><em><strong>In Short:  </strong>Some information — such as your Internet
                Protocol (IP) address and/or browser and device characteristics — is collected automatically when you
                visit our <span data-custom-class="body_text"><span style="font-size: 15px;"><span
                            data-custom-class="body_text">
                            <bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt>
                            <bdt class="block-component"></bdt>
                            <bdt class="block-component"></bdt>
                        </span></span>
        </span>.</em>
        </span>
    </div>
    <div><br></div>
    <div><span data-custom-class="body_text">We automatically collect certain information when you visit, use or
            navigate the <span style="font-size: 15px;"><span data-custom-class="body_text">
                    <bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt>
                </span></span>. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system,
        language preferences, referring URLs, device name, country, location, information about who and when you use our <span style="font-size: 15px;"><span
                    data-custom-class="body_text">
                    <bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt>
                </span></span> and other technical information. This information is primarily needed to maintain the security and operation of our <span style="font-size: 15px;"><span data-custom-class="body_text">
                    <bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt>
                    <bdt class="block-component"></bdt>
                    <bdt class="block-component"></bdt>
                </span></span>, and for our internal analytics and reporting purposes.</span>
        <bdt class="block-component"></bdt>
    </div>
    <div><br></div>
    <div><span data-custom-class="body_text">Like many businesses, we also collect information through cookies and
            similar technologies. <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt>
        </span>
        <bdt class="statement-end-if-in-editor"><span data-custom-class="body_text"></span></bdt>
        <bdt class="block-component"></bdt>
    </div>
    <div><br></div>
    <div><span data-custom-class="body_text">The information we collect includes:</span>
        <bdt class="block-component"></bdt>
    </div>
    <ul>
        <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                        data-custom-class="body_text"><em>Log and Usage Data.</em> Log and usage data is
                        service-related, diagnostic usage and performance information our servers automatically collect
                        when you access or use our <span style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt>
                                <bdt class="block-component"></bdt>
                                <bdt class="block-component"></bdt>
                            </span></span> and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the
            <span
                style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt>
                                <bdt class="block-component"></bdt>
                                <bdt class="block-component"></bdt>
                            </span></span> (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error
                reports (sometimes called 'crash dumps') and hardware settings).</span>
                </span>
                </span><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                                <bdt class="statement-end-if-in-editor"></bdt>
                            </span></span>
                </span>
                </span>
                </span>
        </li>
    </ul>
    <div>
        <bdt class="block-component"></bdt>
    </div>
    <ul>
        <li><span data-custom-class="body_text">
                <font color="#595959"><span style="font-size: 15px;"><em>Device Data. </em>We collect device data such
                        as information about your computer, phone, tablet or other device you use to access the </span>
            </font><span style="font-size: 15px;"><span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt>
                        <bdt class="block-component"></bdt>
                        <bdt class="block-component"></bdt>
                    </span></span>
            <font color="#595959"><span style="font-size: 15px;">. Depending on the device used, this device data
                        may include information such as your IP address (or proxy server), device application
                        identification numbers, location, browser type, hardware model Internet service provider and/or
                        mobile carrier, operating system configuration information.</span></font>
            </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                        data-custom-class="body_text"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);">
                                <bdt class="statement-end-if-in-editor"></bdt>
                            </span></span>
            </span>
            </span>
            </span>
        </li>
    </ul>
    <div>
        <bdt class="block-component"></bdt>
        </span>
        </span>
        </span>
        </span>
        </span>
        </li>
        </ul>
        <div>
            <bdt class="block-component"></bdt>
            </bdt>
            <bdt class="statement-end-if-in-editor"></bdt><span data-custom-class="body_text"><span
                    style="font-size: 15px;">
                    <bdt class="statement-end-if-in-editor">
                        <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                                    data-custom-class="body_text"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text">
                                            <bdt class="statement-end-if-in-editor">
                                                <bdt class="statement-end-if-in-editor">
                                                    <bdt class="block-component"></bdt>
                                                </bdt>
                                        </span></span>
            </span>
            </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                    data-custom-class="body_text"><span
                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">
                                            <bdt class="statement-end-if-in-editor">
                                                <bdt class="block-component"></bdt>
                                            </bdt>
                                        </span></span>
            </span>
            </span>
            </bdt>
            </span>
            </span>
            <bdt class="block-component"></bdt>
            </p>
            <p style="font-size: 15px; line-height: 1.5;"><br></p>
            <p id="infouse" style="font-size: 15px;"><span style="color: rgb(0, 0, 0);"><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">2. HOW DO WE USE YOUR
                                INFORMATION?</span></span>
                </strong>
                </span>
            </p>
            <p style="font-size: 15px;"><span data-custom-class="body_text"><em><strong>In Short:  </strong>We process
                        your information for purposes based on legitimate business interests, the fulfillment of our
                        contract with you, compliance with our legal obligations, and/or your consent.</em></span></p>
            <p style="font-size: 15px;"><span data-custom-class="body_text">We use personal information collected via
                    our <span style="font-size: 15px;"><span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt>
                            <bdt class="block-component"></bdt>
                            <bdt class="block-component"></bdt>
                        </span></span> for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you,
                with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
                </span>
                <bdt class="block-component"></bdt>
            </p>
            <p style="font-size: 15px;"><span data-custom-class="body_text">We use the information we collect or
                    receive:</span>
                <bdt class="block-component"></bdt>
            </p>
            <ul>
                <li><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                                            data-custom-class="body_text">To facilitate account creation and logon
                                            process.</span></strong><span data-custom-class="body_text"> If you choose
                                        to link your account with us to a third-party account (such as your Google or
                                        Facebook account), we use the information you allowed us to collect from those
                                        third parties to facilitate account creation and logon process for the
                                        performance of the contract. <bdt class="block-component"></bdt>See the section
                                        below headed "</span></span>
                    </span><span data-custom-class="body_text"><a
                                    data-custom-class="link" href="#sociallogins"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);">HOW DO WE HANDLE YOUR SOCIAL
                                            LOGINS</span></span>
                    </a>
                    </span><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">" for further
                                        information.<span style="font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                </span></span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span><br><br></span>
                </li>
                <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                                    data-custom-class="body_text">To post testimonials.</span></strong><span data-custom-class="body_text"> We post testimonials on our <span
                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>Website<bdt
                                                class="statement-end-if-in-editor"></bdt>
                                            <bdt class="block-component"></bdt>
                                            <bdt class="block-component"></bdt>
                                        </span></span>
                    </span> that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the consent of the testimonial. If you wish to update, or delete your testimonial, please contact us at
                    <bdt class="block-component"></bdt>
                    <bdt class="question">support@splinteredglass.solutions </bdt>
                    <bdt class="else-block"></bdt> and be sure to include your name, testimonial location, and contact information.
                    </span>
                    </span>
                    </span><span data-custom-class="body_text"><br><br></span></li>
                <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                                    data-custom-class="body_text">Request feedback.</span></strong><span data-custom-class="body_text"> We may use your information to request feedback and to
                                contact you about your use of our <span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>Website<bdt
                                                class="statement-end-if-in-editor"></bdt>
                                            <bdt class="block-component"></bdt>
                                            <bdt class="block-component"></bdt>
                                        </span></span>
                    </span>.</span>
                    </span>
                    </span><span data-custom-class="body_text"><br><br></span></li>
                <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                                    data-custom-class="body_text">To enable user-to-user
                                    communications.</span></strong><span data-custom-class="body_text"> We may use your
                                information in order to enable user-to-user communications with each user's
                                consent.</span></span>
                    </span><span data-custom-class="body_text"><br><br></span></li>
                <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                                style="font-size: 15px;"><span
                                                                    style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;"><span
                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                style="font-size: 15px;"><span
                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                style="font-size: 15px;"><span
                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                        style="font-size: 15px;"><span
                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                style="font-size: 15px;"><span
                                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                                        style="font-size: 15px;"><span
                                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                                data-custom-class="body_text"><strong>To
                                                                                                                                    manage
                                                                                                                                    user
                                                                                                                                    accounts</strong>.
                                                                                                                                We
                                                                                                                                may
                                                                                                                                use
                                                                                                                                your
                                                                                                                                information
                                                                                                                                for
                                                                                                                                the
                                                                                                                                purposes
                                                                                                                                of
                                                                                                                                managing
                                                                                                                                our
                                                                                                                                account
                                                                                                                                and
                                                                                                                                keeping
                                                                                                                                it
                                                                                                                                in
                                                                                                                                working
                                                                                                                                order.</span></span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    </span>
                    <bdt class="statement-end-if-in-editor"></bdt>
                </li>
            </ul>
            <p style="font-size: 15px;">
                <bdt class="block-component"></bdt>
                </li>
                </ul>
                <p style="font-size: 15px;">
                    <bdt class="block-component"></bdt>
                    </li>
                    </ul>
                    <p style="font-size: 15px;">
                        <bdt class="block-component"></bdt>
                    </p>
                    <ul>
                        <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                                    data-custom-class="body_text">To send you marketing and promotional
                                    communications.</span></strong><span data-custom-class="body_text"> We and/or our
                                third-party marketing partners may use the personal information you send to us for our
                                marketing purposes, if this is in accordance with your marketing preferences. For
                                example, when expressing an interest in obtaining information about us or our <span
                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>Website<bdt
                                                class="statement-end-if-in-editor"></bdt>
                                            <bdt class="block-component"></bdt>
                                            <bdt class="block-component"></bdt>
                                        </span></span>
                            </span>, subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time (see the "</span>
                            </span>
                            </span><span data-custom-class="body_text"><a
                            data-custom-class="link" href="#privacyrights"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);">WHAT ARE YOUR PRIVACY
                                    RIGHTS</span></span>
                            </a>
                            </span><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">"
                                below).</span></span>
                            </span><span data-custom-class="body_text"><br><br></span></li>
                        <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                                    data-custom-class="body_text">Deliver targeted advertising to
                                    you. </span></strong><span data-custom-class="body_text">We may use your information
                                to develop and display personalized content and advertising (and work with third parties
                                who do so) tailored to your interests and/or location and to measure its effectiveness.
                                <bdt class="block-component"></bdt></span></span>
                            </span>
                            <bdt class="statement-end-if-in-editor"></bdt>
                        </li>
                    </ul>
                    <p style="font-size: 15px;">
                        <bdt class="block-component"></bdt>
                        </li>
                        </ul>
                        <p style="font-size: 15px;">
                            <bdt class="block-component">
                                <bdt class="statement-end-if-in-editor"></bdt>
                            </bdt>
                        </p>
                        <p style="font-size: 15px;"><br></p>
                        <p id="infoshare" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
                            style="font-size: 19px;"><span data-custom-class="heading_1">3. WILL YOUR INFORMATION BE
                                SHARED WITH ANYONE?</span></span>
                            </strong>
                            </span>
                        </p>
                        <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span
                                style="font-size: 15px;"><span data-custom-class="body_text">In
                                    Short:</span></span> </em> </strong><span style="font-size: 15px;"><em><span
                                data-custom-class="body_text">We only share information with your consent, to comply
                                with laws, to provide you with services, to protect your rights, or to fulfill business
                                obligations.</span></em>
                            </span>
                            </span>
                        </p>
                        <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">We may
                        process or share your data that we hold based on the following legal basis:</span></span>
                        </div>
                        <ul>
                            <li><span data-custom-class="body_text"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Consent:</strong> We may process
                            your data if you have given us specific consent to use your personal information in a
                            specific purpose.</span><br><br></span>
                            </li>
                            <li><span data-custom-class="body_text"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Legitimate Interests:</strong> We
                            may process your data when it is reasonably necessary to achieve our legitimate business
                            interests.</span><br><br></span>
                            </li>
                            <li><span data-custom-class="body_text"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Performance of a
                                Contract: </strong>Where we have entered into a contract with you, we may process your
                            personal information to fulfill the terms of our contract.</span><br><br></span>
                            </li>
                            <li><span data-custom-class="body_text"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><strong>Legal Obligations:</strong> We may
                            disclose your information where we are legally required to do so in order to comply with
                            applicable law, governmental requests, a judicial proceeding, court order, or legal process,
                            such as in response to a court order or a subpoena (including in response to public
                            authorities to meet national security or law enforcement
                            requirements).</span><br><br></span>
                            </li>
                            <li><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            data-custom-class="body_text"><strong>Vital Interests:</strong> We may disclose your
                            information where we believe it is necessary to investigate, prevent, or take action
                            regarding potential violations of our policies, suspected fraud, situations involving
                            potential threats to the safety of any person and illegal activities, or as evidence in
                            litigation in which we are involved.</span></span>
                            </li>
                        </ul>
                        <p style="font-size:15px;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text">More specifically, we may need to process your data or share
                            your personal information in the following situations:</span></span>
                            </span>
                        </p>
                        <ul>
                            <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                                    data-custom-class="body_text">Business Transfers.</span></strong><span data-custom-class="body_text"> We may share or transfer your information in connection
                                with, or during negotiations of, any merger, sale of company assets, financing, or
                                acquisition of all or a portion of our business to another company.</span></span>
                                </span>
                            </li>
                        </ul>
                        <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                        </span></span>
                            </span>
                            </span>
                            </span>
                            </li>
                            </ul>
                            <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                data-custom-class="body_text">
                                <bdt class="block-component"></bdt>
                            </span></span>
                                </span>
                                </span>
                                </span>
                                </bdt>
                                </span>
                                </span>
                                </span>
                                </span>
                                </span>
                                </li>
                                </ul>
                                <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>
                                        </span></span>
                                    </span>
                                    </span>
                                    </span>
                                    </li>
                                    </ul>
                                    <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                        data-custom-class="body_text">
                                        <bdt class="block-component"></bdt>
                                    </span></span>
                                        </span>
                                        </span>
                                        </span>
                                        </li>
                                        </ul>
                                        <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>
                                        </span></span>
                                            </span>
                                        </div>
                                        <ul>
                                            <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><span
                                                    data-custom-class="body_text">Other Users.</span></strong><span data-custom-class="body_text"> When you share personal information <bdt
                                                    class="block-component"></bdt>(for example, by posting comments,
                                                contributions or other content to the <span
                                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text">
                                                            <bdt class="block-component"></bdt>Website<bdt
                                                                class="statement-end-if-in-editor"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                        </span></span>
                                                </span>)
                                                <bdt class="statement-end-if-in-editor">
                                                </bdt>or otherwise interact with public areas of the <span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text">
                                                            <bdt class="block-component"></bdt>Website<bdt
                                                                class="statement-end-if-in-editor"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                        </span></span>
                                                </span>, such personal information may be viewed by all users and may be publicly made available outside the <span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text">
                                                            <bdt class="block-component"></bdt>Website<bdt
                                                                class="statement-end-if-in-editor"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                        </span></span>
                                                </span> in perpetuity.
                                                <bdt class="block-component"></bdt>If you interact with other users of our <span style="font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text">
                                                            <bdt class="block-component"></bdt>Website<bdt
                                                                class="statement-end-if-in-editor"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                        </span></span>
                                                </span> and register for our <span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text">
                                                            <bdt class="block-component"></bdt>Website<bdt
                                                                class="statement-end-if-in-editor"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                        </span></span>
                                                </span> through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity.
                                                <bdt class="statement-end-if-in-editor"></bdt>Similarly, other users will be able to view descriptions of your activity, communicate with you within our <span style="font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text">
                                                            <bdt class="block-component"></bdt>Website<bdt
                                                                class="statement-end-if-in-editor"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                            <bdt class="block-component"></bdt>
                                                        </span></span>
                                                </span>, and view your profile.
                                                </span>
                                                </span>
                                                </span><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                    </span></span>
                                                </span>
                                                </span>
                                                </span>
                                            </li>
                                        </ul>
                                        <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px;"><span data-custom-class="body_text">
                                                <bdt class="block-component"></bdt>
                                            </span></span>
                                            </span>
                                            </span>
                                            </span>
                                            </li>
                                            </ul>
                                            <div><span style="color: rgb(89, 89, 89);">
                                        <bdt class="block-component"></bdt>
                                    </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                                                        <bdt class="block-component"><span
                                                                data-custom-class="heading_1"></span></bdt>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                            </div>
                                            <p style="font-size:15px;"><br></p>
                                            <div><span id="cookies" style="color: rgb(0, 0, 0);"><strong><span
                                                style="font-size: 19px;"><span data-custom-class="heading_1">4. DO WE
                                                    USE COOKIES AND OTHER TRACKING
                                                    TECHNOLOGIES?</span></span>
                                                </strong>
                                                </span>
                                            </div>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">In
                                                        Short:</span></span> </em> </strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We may use
                                                    cookies and other tracking technologies to collect and store your
                                                    information.</span></em>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">We may use cookies and similar tracking
                                            technologies (like web beacons and pixels) to access or store information.
                                            Specific information about how we use such technologies and how you can
                                            refuse certain cookies is set out in our Cookie Notice<bdt
                                                class="block-component"></bdt>.</span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                                data-custom-class="body_text">
                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                            </span><span data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>
                                                                <bdt class="block-component"></bdt>
                                                            </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
                                            <p id="sociallogins" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                                                    data-custom-class="heading_1">5. HOW DO WE HANDLE YOUR SOCIAL
                                                    LOGINS?</span></span>
                                                </strong>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">In
                                                        Short:</span></span> </em> </strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">If you
                                                    choose to register or log in to our services using a social media
                                                    account, we may have access to certain information about
                                                    you.</span></em>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">Our
                                                <span style="font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><span
                                                                style="font-size: 15px;"><span
                                                                    style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">
                                                                        <bdt class="block-component"></bdt>Website<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        <bdt class="block-component"></bdt>
                                                                        <bdt class="block-component"></bdt>
                                                                    </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span> offers you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information
                                                about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile
                                                picture as well as other information you choose to make public on such social media platform.
                                                <bdt class="block-component">
                                                    </span>
                                                </bdt>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">We will use the information we receive only
                                            for the purposes that are described in this privacy notice<span
                                                style="color: rgb(89, 89, 89);"> </span>or that are otherwise made clear to you on the relevant <span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>Website<bdt
                                                                        class="statement-end-if-in-editor"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to
                                                understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.</span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                        style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                                style="font-size: 15px;"><span
                                                                    style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">
                                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                                    </span>
                                                <bdt class="block-component"><span data-custom-class="body_text">
                                                                            <bdt class="block-component"></bdt>
                                                                            <bdt class="block-component"></bdt>
                                                                        </span>
                                                    </span>
                                                    </span>
                                                    </span>
                                                    </span>
                                                    </span>
                                                    </span>
                                                    </span>
                                                    </span>
                                                    </span>
                                            </p>
                                            <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
                                            <p id="3pwebsites" style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                                                    data-custom-class="heading_1">6. WHAT IS OUR STANCE ON THIRD-PARTY
                                                    WEBSITES?</span></span>
                                                </strong>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">In
                                                        Short:</span></span> </em> </strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We are not
                                                    responsible for the safety of any information that you share with
                                                    third-party providers who advertise, but are not affiliated with,
                                                    our Website.</span></em>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">The <span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>Website<bdt
                                                                        class="statement-end-if-in-editor"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span><span data-custom-class="body_text"> may contain advertisements from third parties
                                            that are not affiliated with us and which may link to other websites, online
                                            services or mobile applications. We cannot guarantee the safety and privacy
                                            of data you provide to any third parties. Any data collected by third
                                            parties is not covered by this privacy notice. We are not responsible for
                                            the content or privacy and security practices and policies of any third
                                            parties, including other websites, services or applications that may be
                                            linked to or from the <span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>Website<bdt
                                                                        class="statement-end-if-in-editor"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span><span data-custom-class="body_text">. You should review the policies of such third
                                            parties and contact them directly to respond to your questions.</span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    style="color: rgb(89, 89, 89);">
                                                                    <bdt class="statement-end-if-in-editor"><span
                                                                            data-custom-class="body_text"></span></bdt>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
                                            <p id="inforetain" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                                                    data-custom-class="heading_1">7. HOW LONG DO WE KEEP YOUR
                                                    INFORMATION?</span></span>
                                                </strong>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">In
                                                        Short:</span></span> </em> </strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We keep
                                                    your information for as long as necessary to fulfill the purposes
                                                    outlined in this privacy notice<span
                                                        style="color: rgb(89, 89, 89);"> </span>unless otherwise required by law.</span>
                                                </em>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">We will
                                                only keep your personal information for as long as it is necessary for
                                                the purposes set out in this privacy notice, unless a longer retention
                                                period is required or permitted by law (such as tax, accounting or other
                                                legal requirements). No purpose in this notice will require us keeping
                                                your personal information for longer than <bdt class="block-component">
                                                </bdt></span></span>
                                                </span><span data-custom-class="body_text">
                                        <bdt class="block-component"></bdt>the period of time in which users have an
                                        account with us<bdt class="block-component"></bdt>
                                    </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">
                                                <bdt class="else-block"></bdt>.
                                            </span></span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">When we have no ongoing legitimate business
                                            need to process your personal information, we will either delete or
                                            anonymize such information, or, if this is not possible (for example,
                                            because your personal information has been stored in backup archives), then
                                            we will securely store your personal information and isolate it from any
                                            further processing until deletion is possible.</span></span><span style="color: rgb(89, 89, 89);">
                                        <bdt class="block-component"></bdt>
                                    </span></p>
                                            <div style="line-height: 1.5;"><br></div>
                                            <p id="infosafe" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                                                    data-custom-class="heading_1">8. HOW DO WE KEEP YOUR INFORMATION
                                                    SAFE?</span></span>
                                                </strong>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">In
                                                        Short:</span></span> </em> </strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We aim to
                                                    protect your personal information through a system of organizational
                                                    and technical security measures.</span></em>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">We have implemented appropriate technical and
                                            organizational security measures designed to protect the security of any
                                            personal information we process. However, despite our safeguards and efforts
                                            to secure your information, no electronic transmission over the Internet or
                                            information storage technology can be guaranteed to be 100% secure, so we
                                            cannot promise or guarantee that hackers, cybercriminals, or other
                                            unauthorized third parties will not be able to defeat our security, and
                                            improperly collect, access, steal, or modify your information. Although we
                                            will do our best to protect your personal information, transmission of
                                            personal information to and from our <span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>Website<bdt
                                                                        class="statement-end-if-in-editor"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span> is at your own risk. You should only access the <span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>Website<bdt
                                                                        class="statement-end-if-in-editor"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span> within a secure environment.</span>
                                                </span><span style="color: rgb(89, 89, 89);">
                                        <bdt class="statement-end-if-in-editor"></bdt>
                                    </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>
                                        </span></span>
                                            </p>
                                            <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
                                            <p id="infominors" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                                                    data-custom-class="heading_1">9. DO WE COLLECT INFORMATION FROM
                                                    MINORS?</span></span>
                                                </strong>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">In
                                                        Short:</span></span> </em> </strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">We do not
                                                    knowingly collect data from or market to children under 18 years of
                                                    age.</span></em>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">We do not knowingly solicit data from or
                                            market to children under 18 years of age. By using the <span
                                                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>Website<bdt
                                                                        class="statement-end-if-in-editor"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the <span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>Website<bdt
                                                                        class="statement-end-if-in-editor"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.
                                                If you become aware of any data we may have collected from children under age 18, please contact us at
                                                <bdt class="block-component"></bdt>
                                                <bdt class="question">support@splinteredglass.solutions </bdt>
                                                <bdt class="else-block"></bdt>.
                                                </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                data-custom-class="body_text">
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                            </span></span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
                                            <p id="privacyrights" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                                                    data-custom-class="heading_1">10. WHAT ARE YOUR PRIVACY
                                                    RIGHTS?</span></span>
                                                </strong>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">In
                                                        Short:</span></span> </em> </strong><span style="font-size: 15px;"><span data-custom-class="body_text"><em>
                                                    <bdt class="block-component"></bdt>In some regions, such as the
                                                    European Economic Area, you have rights that allow you greater
                                                    access to and control over your personal information. <bdt
                                                        class="statement-end-if-in-editor"></bdt>You may review, change,
                                                    or terminate your account at any time.
                                                </em></span>
                                                <bdt class="block-component"></bdt>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">In some
                                                regions (like the European Economic Area), you have certain rights under
                                                applicable data protection laws. These may include the right (i) to
                                                request access and obtain a copy of your personal information, (ii) to
                                                request rectification or erasure; (iii) to restrict the processing of
                                                your personal information; and (iv) if applicable, to data portability.
                                                In certain circumstances, you may also have the right to object to the
                                                processing of your personal information. To make such a request, please
                                                use the</span></span> </span><span data-custom-class="body_text"><a
                                            data-custom-class="link" href="#contact"><span
                                                style="font-size: 15px;"><span style="color: rgb(48, 48, 241);">contact
                                                    details</span></span>
                                                </a>
                                                </span><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"> provided below. We will consider and act
                                                upon any request in accordance with applicable data protection
                                                laws.</span></span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">If we
                                                are relying on your consent to process your personal information, you
                                                have the right to withdraw your consent at any time. Please note however
                                                that this will not affect the lawfulness of the processing before its
                                                withdrawal, nor will it affect the processing of your personal
                                                information conducted in reliance on lawful processing grounds other
                                                than consent.<span style="color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px;">
                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                    </span></span>
                                                </span>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you
                                                are resident in the European Economic Area and you believe we are
                                                unlawfully processing your personal information, you also have the right
                                                to complain to your local data protection supervisory authority. You can
                                                find their contact details here:<span
                                                    style="font-size: 15px;"> </span><span style="color: rgb(48, 48, 241);"><span
                                                        data-custom-class="body_text"><a data-custom-class="link"
                                                            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                            rel="noopener noreferrer" target="_blank"><span
                                                                style="font-size: 15px;">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span><span data-custom-class="body_text"><span style="color: rgb(48, 48, 241);"><span
                                                style="font-size: 15px;">.</span></span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you
                                                are resident in Switzerland, the contact details for the data protection
                                                authorities are available here: <span
                                                    style="color: rgb(48, 48, 241);"><span
                                                        data-custom-class="body_text"><span style="font-size: 15px;"><a
                                                                data-custom-class="link"
                                                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                rel="noopener noreferrer"
                                                                target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);">
                                            <bdt class="block-component"><span data-custom-class="body_text"></span>
                                                </bdt>
                                                <bdt class="block-component"></bdt>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you
                                                have questions or comments about your privacy rights, you may email us
                                                at <bdt class="question">support@splinteredglass.solutions</bdt>.</span>
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                </span>
                                                </span>
                                            </p>
                                            <div style="line-height: 1.1;"><br></div>
                                            <div><span style="font-size: 16px;"><span style="color: rgb(0, 0, 0);"><strong><span
                                                    data-custom-class="heading_2">Account
                                                    Information</span></strong>
                                                </span>
                                                </span>
                                            </div>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you
                                                would at any time like to review or change the information in your
                                                account or terminate your account, you can:<bdt
                                                    class="forloop-component"></bdt></span></span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;">    ■  <span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                <bdt class="question">Log in to your account settings and update your
                                                    user account.</bdt>
                                                <bdt class="forloop-component"></bdt>
                                            </span></span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">Upon
                                                your request to terminate your account, we will deactivate or delete
                                                your account and information from our active databases. However, we may
                                                retain some information in our files to prevent fraud, troubleshoot
                                                problems, assist with any investigations, enforce our Terms of Use
                                                and/or comply with applicable legal requirements.</span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);">
                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                                <bdt class="block-component"></bdt>
                                                            </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><strong><u><span
                                                        data-custom-class="body_text">Cookies and similar
                                                        technologies:</span></u> </strong><span data-custom-class="body_text">Most Web browsers are set to accept
                                                cookies by default. If you prefer, you can usually choose to set your
                                                browser to remove cookies and to reject cookies. If you choose to remove
                                                cookies or reject cookies, this could affect certain features or
                                                services of our <span
                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;"><span
                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">
                                                                                <bdt class="block-component"></bdt>
                                                                                Website<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt>
                                                                                <bdt class="block-component"></bdt>
                                                                                <bdt class="block-component"></bdt>
                                                                            </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>. To opt-out of interest-based advertising by advertisers on our <span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;"><span
                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">
                                                                                <bdt class="block-component"></bdt>
                                                                                Website<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt>
                                                                                <bdt class="block-component"></bdt>
                                                                                <bdt class="block-component"></bdt>
                                                                            </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span><span data-custom-class="body_text"> visit</span></span> </span><span style="color: rgb(48, 48, 241);"><span data-custom-class="body_text"><a
                                                data-custom-class="link" href="http://www.aboutads.info/choices/"
                                                rel="noopener noreferrer" target="_blank"><span
                                                    style="font-size: 15px;">http://www.aboutads.info/choices/</span></a>
                                                </span>
                                                </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">. <bdt class="block-component"></bdt><span
                                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px;"><span
                                                                        style="color: rgb(89, 89, 89);">
                                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                                    </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span><span data-custom-class="body_text">
                                                <bdt class="block-component"></bdt>
                                            </span></span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px;"><strong><u><span
                                                        data-custom-class="body_text">Opting out of email
                                                        marketing:</span></u> </strong><span data-custom-class="body_text">You can unsubscribe from our marketing
                                                email list at any time by clicking on the unsubscribe link in the emails
                                                that we send or by contacting us using the details provided below. You
                                                will then be removed from the marketing email list – however, we may
                                                still communicate with you, for example to send you service-related
                                                emails that are necessary for the administration and use of your
                                                account, to respond to service requests, or for other non-marketing
                                                purposes. To otherwise opt-out, you may:<bdt class="forloop-component">
                                                </bdt></span></span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;">    ■ <span style="color: rgb(89, 89, 89);"> <span
                                            style="font-size: 15px;"><span data-custom-class="body_text">
                                                <bdt class="question">Access your account settings and update your
                                                    preferences.</bdt>
                                                <bdt class="forloop-component"></bdt><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span style="font-size: 15px;">
                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                            </span></span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px; line-height: 1.5;"><br></p>
                                            <p id="DNT" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
                                                style="font-size: 19px;"><span data-custom-class="heading_1">11.
                                                    CONTROLS FOR DO-NOT-TRACK FEATURES</span></span>
                                                </strong>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px;"><span data-custom-class="body_text">Most web
                                                browsers and some mobile operating systems and mobile applications
                                                include a Do-Not-Track (“DNT”) feature or setting you can activate to
                                                signal your privacy preference not to have data about your online
                                                browsing activities monitored and collected. At this stage, no uniform
                                                technology standard for recognizing and implementing DNT signals has
                                                been finalized. As such, we do not currently respond to DNT browser
                                                signals or any other mechanism that automatically communicates your
                                                choice not to be tracked online. If a standard for online tracking is
                                                adopted that we must follow in the future, we will inform you about that
                                                practice in a revised version of this privacy
                                                notice.</span></span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px; line-height: 1.5;"><br></p>
                                            <p id="caresidents" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                                                    data-custom-class="heading_1">12. DO CALIFORNIA RESIDENTS HAVE
                                                    SPECIFIC PRIVACY RIGHTS?</span></span>
                                                </strong>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">In
                                                        Short:</span></span> </em> </strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">Yes, if you
                                                    are a resident of California, you are granted specific rights
                                                    regarding access to your personal
                                                    information.</span></em>
                                                </span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">California Civil Code Section 1798.83,
                                                also known as the “Shine The Light” law, permits our users who are
                                                California residents to request and obtain from us, once a year and free
                                                of charge, information about categories of personal information (if any)
                                                we disclosed to third parties for direct marketing purposes and the
                                                names and addresses of all third parties with which we shared personal
                                                information in the immediately preceding calendar year. If you are a
                                                California resident and would like to make such a request, please submit
                                                your request in writing to us using the contact information provided
                                                below.</span></span>
                                                </span>
                                            </p>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">If you are under 18 years of age, reside in
                                            California, and have a registered account with <span
                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                                data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>the Website<bdt
                                                                    class="statement-end-if-in-editor"></bdt>
                                                                <bdt class="block-component"></bdt>
                                                                <bdt class="block-component"></bdt>
                                                            </span></span>
                                                </span>
                                                </span>
                                                </span>, you have the right to request removal of unwanted data that you publicly post on the <span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                                data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>Website<bdt
                                                                    class="statement-end-if-in-editor"></bdt>
                                                                <bdt class="block-component"></bdt>
                                                                <bdt class="block-component"></bdt>
                                                            </span></span>
                                                </span>
                                                </span>
                                                </span>. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California.
                                                We will make sure the data is not publicly displayed on the <span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                                data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>Website<bdt
                                                                    class="statement-end-if-in-editor"></bdt>
                                                                <bdt class="block-component"></bdt>
                                                                <bdt class="block-component"></bdt>
                                                            </span></span>
                                                </span>
                                                </span>
                                                </span>, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).  </span><span data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>
                                        </span></span>
                                            </p>
                                            <div style="line-height: 1.1;"><br></div>
                                            <div><span style="font-size: 16px;"><span style="color: rgb(0, 0, 0);"><strong><span
                                                    data-custom-class="heading_2">CCPA Privacy
                                                    Notice</span></strong>
                                                </span>
                                                </span>
                                            </div>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">The California Code of Regulations defines a
                                            "resident" as:</span></span>
                                            </p>
                                            <p style="font-size: 15px; margin-left: 20px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">(1) every individual who is in the State of
                                            California for other than a temporary or transitory purpose
                                            and</span></span>
                                            </p>
                                            <p style="font-size: 15px; margin-left: 20px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">(2) every individual who is domiciled in the
                                            State of California who is outside the State of California for a temporary
                                            or transitory purpose</span></span>
                                            </p>
                                            <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">All other individuals are defined as
                                            "non-residents."</span></span>
                                            </div>
                                            <div><br></div>
                                            <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">If this definition of "resident" applies to
                                            you, certain rights and obligations apply regarding your personal
                                            information.</span></span>
                                            </div>
                                            <div><br></div>
                                            <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text"><strong>What categories of personal
                                                information do we collect?</strong></span></span>
                                            </div>
                                            <div><br></div>
                                            <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">We have collected the following categories of
                                            personal information in the past twelve (12) months: <bdt
                                                class="block-component"></bdt></span></span>
                                            </div>
                                            <div><span data-custom-class="body_text"><br></span></div>
                                            <table style="width: 100%; margin-right: calc(0%); margin-left: calc(0%);">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-width: 1px; border-color: black; border-style: solid;">
                                                            <span data-custom-class="body_text"><strong>Category</strong></span>
                                                        </td>
                                                        <td style="width: 51.7352%; border-top: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text"><strong>Examples</strong></span>
                                                        </td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                                                            <span data-custom-class="body_text"><strong>Collected</strong></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-bottom: 1px solid black; border-left: 1px solid black; border-right: 1px solid black;">
                                                            <span data-custom-class="body_text">A. Identifiers</span></td>
                                                        <td style="width: 51.7352%; border-bottom: 1px solid black; border-right: 1px solid black;">
                                                            <span data-custom-class="body_text">Contact details, such as real name,
                                                    alias, postal address, telephone or mobile contact number, unique
                                                    personal identifier, online identifier, Internet Protocol address,
                                                    email address and account name<br></span></td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>NO<bdt
                                                        class="statement-end-if-in-editor"></bdt>
                                                </span><br><br></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">B. Personal information categories
                                                    listed in the California Customer Records statute</span></td>
                                                        <td style="width: 51.7352%; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">Name, contact information,
                                                    education, employment, employment history and financial
                                                    information<br></span></td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">YES</span><br><br></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">C. Protected classification
                                                    characteristics under California or federal law</span></td>
                                                        <td style="width: 51.7352%; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">Gender and date of birth<br></span>
                                                        </td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>NO<bdt
                                                        class="statement-end-if-in-editor"></bdt>
                                                </span><br><br></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">D. Commercial information</span>
                                                        </td>
                                                        <td style="width: 51.7352%; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">Transaction information, purchase
                                                    history, financial details and payment information<br></span></td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>NO<bdt
                                                        class="statement-end-if-in-editor"></bdt>
                                                </span><br><br></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">E. Biometric information</span></td>
                                                        <td style="width: 51.7352%; border-bottom: 1px solid black; border-right: 1px solid black;">
                                                            <span data-custom-class="body_text">Fingerprints and voiceprints</span>
                                                        </td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>NO<bdt
                                                        class="statement-end-if-in-editor"></bdt>
                                                </span><br><span data-custom-class="body_text"><br></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">F. Internet or other similar network
                                                    activity</span></td>
                                                        <td style="width: 51.7352%; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">Browsing history, search history,
                                                    online behavior, interest data, and interactions with our and other
                                                    websites, applications, systems and advertisements<br></span></td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>NO<bdt
                                                        class="statement-end-if-in-editor"></bdt>
                                                </span><br><br></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">G. Geolocation data</span></td>
                                                        <td style="width: 51.7352%; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">Device location</span></td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>NO<bdt
                                                        class="statement-end-if-in-editor"></bdt>
                                                </span><br><span data-custom-class="body_text"><br></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">H. Audio, electronic, visual,
                                                    thermal, olfactory, or similar information</span></td>
                                                        <td style="width: 51.7352%; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">Images and audio, video or call
                                                    recordings created in connection with our business
                                                    activities<br></span></td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>NO<bdt
                                                        class="statement-end-if-in-editor"></bdt>
                                                </span><br><span data-custom-class="body_text"><br></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">I. Professional or
                                                    employment-related information</span></td>
                                                        <td style="width: 51.7352%; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">Business contact details in order to
                                                    provide you our services at a business level, job title as well as
                                                    work history and professional qualifications if you apply for a job
                                                    with us<br></span></td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>NO<bdt
                                                        class="statement-end-if-in-editor"></bdt>
                                                </span><br><span data-custom-class="body_text"><br></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">J. Education Information</span></td>
                                                        <td style="width: 51.7352%; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">Student records and directory
                                                    information<br></span></td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>NO<bdt
                                                        class="statement-end-if-in-editor"></bdt>
                                                </span><br><span data-custom-class="body_text"><br></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width: 33.4086%; border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">K. Inferences drawn from other
                                                    personal information</span></td>
                                                        <td style="width: 51.7352%; border-right: 1px solid black; border-bottom: 1px solid black;">
                                                            <span data-custom-class="body_text">Inferences drawn from any of the
                                                    collected personal information listed above to create a profile or
                                                    summary about, for example, an individual’s preferences and
                                                    characteristics<br></span></td>
                                                        <td style="width: 14.9677%; border-right: 1px solid black; border-bottom: 1px solid black; text-align: center;">
                                                            <br><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>NO
                                                </span>
                                                            <bdt class="statement-end-if-in-editor"><span data-custom-class="body_text"></span></bdt><br><br>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div>
                                                <bdt class="block-component"></bdt>
                                                </span>
                                                </span>
                                            </div>
                                            <div><br></div>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">We may also collect other personal information
                                            outside of these categories in instances where you interact with us
                                            in-person, online, or by phone or mail in the context of:</span></span>
                                            </p>
                                            <ul>
                                                <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                    data-custom-class="body_text">Receiving help through our customer
                                                    support channels</span></span>
                                                    </span><br><br></li>
                                                <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                    data-custom-class="body_text">Participation in customer surveys or
                                                    contests; and</span></span>
                                                    </span><br><br></li>
                                                <li><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                    data-custom-class="body_text">Facilitation in the delivery of our
                                                    Services and to respond to your inquiries</span></span>
                                                    </span>
                                                </li>
                                            </ul>
                                            <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text"><strong>How do we use and share your personal
                                                information?</strong>
                                            <bdt class="block-component"></bdt></strong>
                                        </span></span>
                                                </bdt>
                                                </span>
                                                </span>
                                                </bdt>
                                                </span>
                                                </span>
                                                </span>
                                                </li>
                                                </ul>
                                                <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">
                                            <bdt class="block-component">
                                                <bdt class="block-component"></bdt>
                                            </bdt>
                                        </span></span>
                                                    </bdt>
                                                    </span>
                                                    </span>
                                                    </bdt>
                                                    </span>
                                                    </span>
                                                    </span>
                                                    </li>
                                                    </ul>
                                                    <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">More information about our data collection and
                                            sharing practices can be found in this privacy notice</span><span data-custom-class="body_text"><span
                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>
                                                </span></span>
                                                        </span><span data-custom-class="body_text">.<span
                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>
                                                </span></span>
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <div><br></div>
                                                    <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">You may contact us <bdt
                                                class="block-component"></bdt>by email at <bdt class="question">
                                                support@splinteredglass.solutions </bdt>, <bdt class="block-component">
                                            </bdt>
                                            <bdt class="block-component"></bdt>
                                        </span></span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>
                                            <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                                        </span></span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">or by referring to the contact details at the
                                            bottom of this document.</span></span>
                                                    </div>
                                                    <div><br></div>
                                                    <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">If you are using an authorized agent to
                                            exercise your right to opt-out, we may deny a request if the authorized
                                            agent does not submit proof that they have been validly authorized to act on
                                            your behalf.</span></span>
                                                    </div>
                                                    <div><br></div>
                                                    <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text"><span
                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text"><strong>Will your information be
                                                        shared with anyone else?</strong></span></span>
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <div><br></div>
                                                    <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">We may disclose your personal information with
                                            our service providers pursuant to a written contract between us and each
                                            service provider. Each service provider is a for-profit entity that
                                            processes the information on our behalf.<bdt class="block-component"></bdt>
                                            </span></span>
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <div><br></div>
                                                    <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">We may use your personal information for our
                                            own business purposes, such as for undertaking internal research for
                                            technological development and demonstration. This is not considered to be
                                            "selling" of your personal data.<bdt class="block-component"></bdt>
                                            </span></span>
                                                    </div>
                                                    <div><br></div>
                                                    <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">
                                            <bdt class="question">Splintered Glass Solutions LLC</bdt> has not disclosed
                                            or sold any personal information to third parties for a business or
                                            commercial purpose in the preceding twelve (12) months. <bdt
                                                class="question">Splintered Glass Solutions LLC</bdt> will not sell
                                            personal information in the future belonging to website visitors, users and
                                            other consumers.<span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text"><span
                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                            data-custom-class="body_text">
                                                            <bdt class="statement-end-if-in-editor"></bdt>
                                                        </span></span>
                                                        </span>
                                                        </span>
                                                        <bdt class="block-component"></bdt>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </bdt>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>
                                        </span></span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span><span data-custom-class="body_text">
                                        <bdt class="block-component"></bdt>
                                    </span></span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <div><br></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"><strong>Your rights with respect to your
                                                    personal data</strong></span></span>
                                                        </span>
                                                    </div>
                                                    <div><span style="color: rgb(0, 0, 0);"><br></span></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"><u>Right to request deletion of the data -
                                                    Request to delete</u></span></span>
                                                        </span>
                                                    </div>
                                                    <div><span style="color: rgb(0, 0, 0);"><br></span></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text">You can ask for the deletion of your
                                                personal information. If you ask us to delete your personal information,
                                                we will respect your request and delete your personal information,
                                                subject to certain exceptions provided by law, such as (but not limited
                                                to) the exercise by another consumer of his or her right to free speech,
                                                our compliance requirements resulting from a legal obligation or any
                                                processing that may be required to protect against illegal
                                                activities.</span></span>
                                                        </span>
                                                    </div>
                                                    <div><span style="color: rgb(0, 0, 0);"><br></span></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"><u>Right to be informed - Request to
                                                    know</u></span></span>
                                                        </span>
                                                    </div>
                                                    <div><span style="color: rgb(0, 0, 0);"><br></span></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text">Depending on the circumstances, you have a
                                                right to know:</span></span>
                                                        </span>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <p style="font-size: 15px;"><span style="color: rgb(0, 0, 0);"><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">whether
                                                        we collect and use your personal
                                                        information;</span></span>
                                                                </span>
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p style="font-size: 15px;"><span style="color: rgb(0, 0, 0);"><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">the
                                                        categories of personal information that we
                                                        collect;</span></span>
                                                                </span>
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                        data-custom-class="body_text">the purposes for which the
                                                        collected personal information is used;</span></span>
                                                                </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p style="font-size: 15px;"><span style="color: rgb(0, 0, 0);"><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">whether
                                                        we sell your personal information to third
                                                        parties;</span></span>
                                                                </span>
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p style="font-size: 15px;"><span style="color: rgb(0, 0, 0);"><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">the
                                                        categories of personal information that we sold or disclosed for
                                                        a business purpose;</span></span>
                                                                </span>
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                        data-custom-class="body_text">the categories of third parties to
                                                        whom the personal information was sold or disclosed for a
                                                        business purpose; and</span></span>
                                                                </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p style="font-size: 15px;"><span style="color: rgb(0, 0, 0);"><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">the
                                                        business or commercial purpose for collecting or selling
                                                        personal information.</span></span>
                                                                </span>
                                                            </p>
                                                        </li>
                                                    </ul>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text">In accordance with applicable law, we are
                                                not obligated to provide or delete consumer information that is
                                                de-identified in response to a consumer request or to re-identify
                                                individual data to verify a consumer request.</span></span>
                                                        </span>
                                                    </div>
                                                    <div><span style="color: rgb(0, 0, 0);"><br></span></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"><u>Right to Non-Discrimination for the
                                                    Exercise of a Consumer's Privacy Rights</u></span></span>
                                                        </span>
                                                    </div>
                                                    <div><span style="color: rgb(0, 0, 0);"><br></span></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text">We will not discriminate against you if
                                                you exercise your privacy rights.</span></span>
                                                        </span>
                                                    </div>
                                                    <div><span style="color: rgb(0, 0, 0);"><br></span></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"><u>Verification
                                                    process</u></span></span>
                                                        </span>
                                                    </div>
                                                    <div><span style="color: rgb(0, 0, 0);"><br></span></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text">Upon receiving your request, we will need
                                                to verify your identity to determine you are the same person about whom
                                                we have the information in our system. These verification efforts
                                                require us to ask you to provide information so that we can match it
                                                with the information you have previously provided us. For instance,
                                                depending on the type of request you submit, we may ask you to provide
                                                certain information so that we can match the information you provide
                                                with the information we already have on file, or we may contact you
                                                through a communication method (e.g. phone or email) that you have
                                                previously provided to us. We may also use other verification methods as
                                                the circumstances dictate.</span></span>
                                                        </span>
                                                    </div>
                                                    <div><span style="color: rgb(0, 0, 0);"><br></span></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text">We will only use personal information
                                                provided in your request to verify your identity or authority to make
                                                the request. To the extent possible, we will avoid requesting additional
                                                information from you for the purposes of verification. If, however, if
                                                we cannot verify your identity from the information already maintained
                                                by us, we may request that you provide additional information for the
                                                purposes of verifying your identity, and for security or
                                                fraud-prevention purposes. We will delete such additionally provided
                                                information as soon as we finish verifying you.</span></span>
                                                        </span>
                                                    </div>
                                                    <div><span style="color: rgb(0, 0, 0);"><br></span></div>
                                                    <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"><u>Other privacy
                                                    rights</u></span></span>
                                                        </span>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <p style="font-size: 15px;"><span style="color: rgb(0, 0, 0);"><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">you may
                                                        object to the processing of your personal
                                                        data</span></span>
                                                                </span>
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <p style="font-size: 15px;"><span style="color: rgb(0, 0, 0);"><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">you may
                                                        request correction of your personal data if it is incorrect or
                                                        no longer relevant, or ask to restrict the processing of the
                                                        data</span></span>
                                                                </span>
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <div><span style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><span
                                                        data-custom-class="body_text">you can designate an authorized
                                                        agent to make a request under the CCPA on your behalf. We may
                                                        deny a request from an authorized agent that does not submit
                                                        proof that they have been validly authorized to act on your
                                                        behalf in accordance with the CCPA.</span></span>
                                                                </span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <p style="font-size: 15px;"><span style="color: rgb(0, 0, 0); font-size: 15px;"><span
                                                    data-custom-class="body_text">you may request to opt-out from future
                                                    selling of your personal information to third parties. Upon
                                                    receiving a request to opt-out, we will act upon the request as soon
                                                    as feasibly possible, but no later than 15 days from the date of the
                                                    request submission.</span></span>
                                                            </p>
                                                        </li>
                                                    </ul>
                                                    <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">To exercise these rights, you can contact us
                                            <span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>by email at <bdt
                                                        class="question">support@splinteredglass.solutions </bdt>, <bdt
                                                        class="block-component"></bdt>
                                                    <bdt class="block-component"></bdt>
                                                </span></span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>
                                                    <bdt class="block-component"><span data-custom-class="body_text">
                                                    </bdt>
                                                </span></span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">or by referring to the contact details at the
                                            bottom of this document. If you have a complaint about how we handle your
                                            data, we would like to hear from you.</span></span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>
                                        </span></span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text"><span
                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text"><span
                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                            data-custom-class="body_text">
                                                            <bdt class="statement-end-if-in-editor"></bdt>
                                                        </span></span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                    </div>
                                                    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
                                                    <p id="policyupdates" style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(0, 0, 0);"><strong><span style="font-size: 19px;"><span
                                                    data-custom-class="heading_1">13. DO WE MAKE UPDATES TO THIS
                                                    NOTICE?</span></span>
                                                        </strong>
                                                        </span>
                                                    </p>
                                                    <p style="font-size:15px;"><span style="color: rgb(89, 89, 89);"><strong><em><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">In
                                                        Short:</span></span> </em> </strong><span style="font-size: 15px;"><em><span data-custom-class="body_text">Yes, we
                                                    will update this notice as necessary to stay compliant with relevant
                                                    laws.</span></em>
                                                        </span>
                                                        </span>
                                                    </p>
                                                    <p style="font-size:15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">We may update this privacy notice<span
                                                style="color: rgb(89, 89, 89);"> </span>from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we
                                                        make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy
                                                        notice<span style="color: rgb(89, 89, 89);"> </span>frequently to be informed of how we are protecting your information.</span>
                                                        </span>
                                                    </p>
                                                    <p style="font-size: 15px; line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><br></span></p>
                                                    <p id="contact" style="font-size:15px;"><span style="color: rgb(0, 0, 0);"><strong><span
                                                style="font-size: 19px;"><span data-custom-class="heading_1">14. HOW CAN
                                                    YOU CONTACT US ABOUT THIS NOTICE?</span></span>
                                                        </strong>
                                                        </span>
                                                    </p>
                                                    <p style="font-size: 15px;"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                            data-custom-class="body_text">If you have questions or comments about this
                                            notice, you may <bdt class="block-component"></bdt>email us at <bdt
                                                class="question">support@splinteredglass.solutions </bdt>
                                            <bdt class="statement-end-if-in-editor"></bdt> or by post to:
                                        </span></span>
                                                    </p>
                                                    <div><span style="color: rgb(89, 89, 89);">
                                        <bdt class="question"><span style="font-size: 15px;"><span
                                                    data-custom-class="body_text">Splintered Glass Solutions
                                                    LLC</span></span> </bdt><span data-custom-class="body_text"><span
                                                style="font-size: 15px;"><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>
                                                </span></span>
                                                    </div>
                                                    <div><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px;">
                                                <bdt class="question">5900 Balcones Drive</bdt>
                                                <bdt class="block-component"></bdt>
                                            </span></span>
                                                        </span>
                                                    </div>
                                                    <div><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px;">
                                                <bdt class="question">Suite 100</bdt>
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                            </span></span>
                                                        </span>
                                                    </div>
                                                    <div><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px;">
                                                <bdt class="question">Austin</bdt>
                                                <bdt class="block-component"></bdt>
                                                <bdt class="block-component"></bdt>, <bdt class="question">TX</bdt>
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                <bdt class="block-component"></bdt>
                                                <bdt class="block-component"></bdt>
                                                <bdt class="question">78731</bdt>
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                            </span></span>
                                                        </span><span data-custom-class="body_text"><span
                                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;">
                                                <bdt class="block-component"></bdt>
                                                <bdt class="block-component"></bdt>
                                                <bdt class="block-component"></bdt>
                                            </span></span>
                                                        </span>
                                                    </div>
                                                    <div><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px;">
                                                <bdt class="question">United States</bdt><span
                                                    data-custom-class="body_text"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                            style="font-size: 15px;"><span
                                                                data-custom-class="body_text"><span
                                                                    style="color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px;">
                                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                                    </span></span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        <bdt class="else-block"></bdt>
                                                        </span>
                                                        </span>
                                                        </span>
                                                        </bdt>
                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                        </span>
                                                        </span>
                                                        </span><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px;">
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                            </span></span>
                                                        </span><span style="color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px;"><span data-custom-class="body_text">
                                                <bdt class="block-component"></bdt>
                                            </span></span>
                                                        </span>
                                                        <bdt class="block-component"></bdt>
                                                    </div>
                                                    <p id="contact" style="font-size: 15px; line-height: 1.5;"><br></p>
                                                    <p id="contact" style="font-size:15px;"><strong><span data-custom-class="heading_1">HOW
                                            CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                                            YOU?</span></strong></p>
                                                    <div><span data-custom-class="body_text">Based on the applicable laws of your country,
                                        you may have the right to request access to the personal information we collect
                                        from you, change that information, or delete it in some circumstances. To
                                        request to review, update, or delete your personal information, please <bdt
                                            class="block-component"></bdt>submit a request form by clicking <span
                                            style="color: rgb(48, 48, 241);"><span data-custom-class="body_text"><span
                                                    style="font-size: 15px;"><a data-custom-class="link"
                                                        href="https://app.termly.io/notify/e32f2412-c46e-4472-8d61-0cd81bf01b85"
                                                        rel="noopener noreferrer"
                                                        target="_blank">here</a></span></span>
                                                        </span>
                                                        <bdt class="block-component"></bdt>. We will respond to your request within 30 days.
                                                        </span>
                                                    </div>
                                                    <style>
                                                        ul {
                                                            list-style-type: square;
                                                        }
                                                        
                                                        ul>li>ul {
                                                            list-style-type: circle;
                                                        }
                                                        
                                                        ul>li>ul>li>ul {
                                                            list-style-type: square;
                                                        }
                                                        
                                                        ol li {
                                                            font-family: Arial;
                                                        }
                                                    </style>
                                        </div>
                                        <div style="color: #595959;font-size: 14px;font-family: Arial;padding-top:16px;">This privacy policy was created using <a style="color: rgb(48, 48, 241) !important;" href="https://termly.io/products/privacy-policy-generator/?ftseo">Termly’s Privacy
                                    Policy Generator</a>.</div>`;
  return (
    <StyledPrivacy id="content" dangerouslySetInnerHTML={{ __html: policy }} />
  );
};

export default Privacy;
