import { Auth } from "aws-amplify";

// AWS Confirm SignUp with MFA
export async function AWSConfirmSignUp(data, handleResp) {
  try {
    const resp = await Auth.confirmSignUp(data["Email"], data["ConfirmCode"]);
    handleResp(resp, "success");
    return true;
  } catch (err) {
    console.log("error confirming sign up", err);
    handleResp(err, "error");
    return false;
  }
}

export async function AWSResendConfirmationCode(email, handleResp) {
  try {
    const resp = await Auth.resendSignUp(email);
    handleResp(resp, "success");
  } catch (err) {
    handleResp(err, "error");
  }
}
