import { _Upsert } from "../Upsert";

/**
 * Function to Upsert a users Actual lines from the db
 * @param  {String} query
 * @param {Integer} update
 * @throws {String}      console.error the error
 */
export async function UpsertActual(query, update) {
  try {
    console.log("Func: UpsertActuals");
    const body = {
      collection: "Actual",
      query: query,
      update: update,
    };

    const resp_u = await _Upsert(body);

    console.info(`Success UpsertActuals: ${resp_u.message}`);
    return true;
  } catch (err) {
    console.error(`FAILED func UpsertActuals: ${err}`);
    return err;
  }
}
