import styled from "styled-components";

export const StyledModal = styled.div`
  animation: createPage 0.5s;
  position: fixed;
  top: 0;
  left: 0;
  /* bottom: 0; */
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
`;

export const ModalBoxSetup = styled.div`
  animation: createPage 0.5s;
  position: absolute;
  max-width: ${(props) => props.width || "98vw"};
  max-height: 80vh;
  width: fit-content;
  overflow-y: auto;
  /* overflow-x: hidden; */
  padding: 0;
  margin: auto;
  box-sizing: border-box;
  z-index: 1;
  box-shadow: 0px 2px 8px ${({ theme }) => theme.colors.dark};
  background: ${({ theme }) => theme.colors.dark};
  border: 0.5px solid ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transition: 0.3s all;
  cursor: default !important;

  /* @media ${({ theme }) => theme.device.tablet} {
    max-width: ${(props) => props.width || "60vw"};
  } */
`;

export const ModalBackground = styled.div`
  animation: createPage 0.5s;
  align-self: flex-start;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: ${({ theme }) => theme.colors.white}c4;
  transition: 0.3s all;
  cursor: default !important;
  @media ${({ theme }) => theme.device.tablet} {
    background: ${({ theme }) => theme.colors.white}1c;
  }
  @keyframes createPage {
    from {
      /* max-height: 0; */
      opacity: 0;
    }
    to {
      /* max-height: inherit; */
      opacity: 1;
    }
  }
`;
