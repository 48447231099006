import React from "react";

import { StyledHome } from "./Home.styled";

const Home = () => {
  return (
    <StyledHome id="content">
      <p>This will be the Home page</p>
    </StyledHome>
  );
};

export default Home;
