import React from "react";

import { StyledPast } from "./Past.styled";

const Past = () => {
  return (
    <StyledPast id="content">
      <p>This will be the Past page</p>
    </StyledPast>
  );
};

export default Past;
