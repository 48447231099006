import styled from "styled-components";

export const StyledSearch = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.grey}96;
  height: 60px;
  padding: 0 0.25rem;
  opacity: 0.9;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white}54;

  .head-item {
    color: ${({ theme }) => theme.colors.white};
    padding: 0.5rem;
    & * {
      text-shadow: 1px 1px ${({ theme }) => theme.colors.middle};
    }
  }
  #to-top-icon {
    font-size: 1.5rem;
  }

  #filter-icon {
    color: ${(props) =>
      props.filtersActive
        ? props.theme.colors.standout
        : props.theme.colors.white};

    font-size: 1.5rem;
  }

  #searchbar {
    flex: 3;
    margin: 0.5rem;
    padding: 0 1rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.grey}53;
    border: 1px solid ${({ theme }) => theme.colors.white}53;
    width: 50%;
    height: 60%;
    border-radius: 999px;
    /* display: flex; */
  }
  #clear {
    position: absolute;
    right: 6.25rem;
  }
  #count {
    min-width: 40px;
    padding: 0.5rem;
    text-shadow: 1px 1px ${({ theme }) => theme.colors.middle};
  }
`;
