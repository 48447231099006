import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  max-width: 100%;
  width: auto;
  align-items: center;
  /* margin: 0.5rem; */

  & .row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 100%;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  & input,
  #_account_guid {
    font-size: 1rem;
    font-weight: 100;

    padding: 0.5rem;
    margin: 0.25rem;
    text-align: center;
    border-style: inset;
    border-bottom-color: ${({ theme }) => theme.colors.middle};
    border-right-color: ${({ theme }) => theme.colors.middle};
    border-top-color: ${({ theme }) => theme.colors.white}00;
    border-left-color: ${({ theme }) => theme.colors.white}00;
    border-width: 1px;
    border-radius: 999px;
    border-image: none 100% / 1 / 0 stretch;
    background-color: ${({ theme }) => theme.colors.middle}00;
    color: ${({ theme }) => theme.colors.white};
    transition: 0.5s all;
    ::placeholder {
      color: ${({ theme }) => theme.colors.light}c4;
      font-weight: 100;
    }
    :hover {
      background: ${({ theme }) => theme.colors.middle}35 none repeat scroll 0%
        0%;
    }
    :focus-visible {
      border-color: ${({ theme }) => theme.colors.light};
      border-top-color: ${({ theme }) => theme.colors.white}00;
      border-left-color: ${({ theme }) => theme.colors.white}00;
    }
  }

  & select option {
    color: ${({ theme }) => theme.colors.dark};
    width: 100%;
  }

  & #_description {
    flex: auto;
    width: 100%;
  }
  & #amountBox {
    width: 120px;
    display: flex;
    align-items: center;
    & input {
      width: 90%;
      position: relative;
      left: -1px;
    }
    margin: 0.5rem;
  }
  & #_account_guid {
    width: 70%;
    & option {
      text-transform: "capitalize";
    }
  }

  & p.error {
    font-size: 0.75rem;
    align-self: flex-end;
    margin: 0 0 3px 0;
    color: ${({ theme }) => theme.colors.standout};
  }

  & #submit {
    font-size: 1rem;
    width: 150px;
    margin: 0.5rem;
    align-self: center;
    justify-self: center;
  }

  .fail {
    border-color: ${({ theme }) => theme.colors.standout}
      ${({ theme }) => theme.colors.dark} ${({ theme }) => theme.colors.dark}
      ${({ theme }) => theme.colors.standout};
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.standout}15;
  }

  .green {
    color: ${({ theme }) => theme.colors.credit};
  }
  .white {
    color: ${({ theme }) => theme.colors.white};
  }

  & .Select__control {
    background-color: transparent;
    border: transparent;
    & .Select__single-value {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  & .Select__menu {
    background-color: ${({ theme }) => theme.colors.white};
    border: transparent;
    color: ${({ theme }) => theme.colors.dark};
  }

  & div .dropdown {
    padding: 0 !important;
  }
`;
