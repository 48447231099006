import styled from "styled-components";

export const StyledPrivacy = styled.div`
  animation: createPage 1s;
  /* max-height: 100%; */
  margin: 0.5rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;

  @keyframes createPage {
    from {
      /* max-height: 0; */
      opacity: 0;
    }
    to {
      /* max-height: inherit; */
      opacity: 1;
    }
  }
`;
