import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";

import { useSnackContext } from "../../../context";

export const SnackWrapper = () => {
  const contextSnack = useSnackContext();

  if (!contextSnack.snack) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={contextSnack.snack ? true : false}
      autoHideDuration={contextSnack.snack === "success" ? 3000 : 8000}
      onClose={() => contextSnack.actions.setSnack(false)}
    >
      <React.Fragment>
        <MuiAlert severity={contextSnack.snack}>
          {contextSnack.snackMessage}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => contextSnack.actions.setSnack(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </MuiAlert>
      </React.Fragment>
    </Snackbar>
    // <Snack
    //   status={contextSnack.snack}
    //   message={contextSnack.snackMessage}
    //   active={contextSnack.snack}
    //   setActive={contextSnack.actions.setSnack}
    // />
  );
};
