import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button, Loader } from "../../../../common";
import { AWSForgotPassword } from "../scripts";
import { StyledForm } from "./Form.styled";

export const ForgotForm = ({ onForgot }) => {
  return (
    <Formik
      initialValues={{
        Email: "",
        // Password: "",
      }}
      validationSchema={Yup.object({
        Email: Yup.string()
          // .max(20, "Must be 20 characters or less")
          .required("Required"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let success = await AWSForgotPassword(values.Email, onForgot);
        if (!success) {
          setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <StyledForm id="ForgotForm" onSubmit={formik.handleSubmit}>
          {formik.touched.Email && formik.errors.Email && (
            <p className="error">{formik.errors.Email}</p>
          )}
          <input
            className={formik.errors.Email ? "fail" : "success"}
            type="email"
            placeholder="Email"
            autoComplete="on"
            name="Email"
            id="Email"
            value={formik.values.Email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {/* {formik.touched.Password && formik.errors.Password && (
            <p className="error">{formik.errors.Password}</p>
          )} */}
          {/* <input
            className={formik.errors.Password ? "fail" : "success"}
            type="password"
            placeholder="Password"
            autoComplete="on"
            name="Password"
            id="Password"
            value={formik.values.Password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          /> */}
          <Button
            id="submit"
            type="submit"
            content={formik.isSubmitting ? "Thinking" : "Get Code"}
            className={formik.isSubmitting ? "loading" : ""}
            disabled={formik.isSubmitting}
          />
          {formik.isSubmitting && <Loader />}
        </StyledForm>
      )}
    </Formik>
  );
};
