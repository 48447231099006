import React from "react";
// import { SignIn } from "./SignIn";
import { Forgot } from "./Forgot";
import { Register } from "./Register";
import { Menu } from "../Drawer/Menu";
import { Validate } from "./Validate";
import { NewPwd } from "./NewPwd";
import { About } from "./About";
import { Connect } from "./Connect";

export function ModalSwitch({ mode }) {
  switch (mode) {
    case "quiz":
      return <p>quiz coming soon!</p>;
    case "terms":
      return <p>terms of service coming soon...</p>;
    case "connect":
      return <Connect />;
    case "about":
      return <About />;
    case "menu":
      return <Menu />;
    case "actualForm":
      return <p>actual form coming soon...</p>;
    case "forgot":
      return <Forgot />;
    case "forgot2":
      return <NewPwd />;
    case "register":
      return <Register />;
    case "validate":
      return <Validate />;
    default:
      return "No Matching Modal Ref";
  }
}
