import styled from "styled-components";

export const StyledGrid = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .card {
    height: 50px;
  }
`;
