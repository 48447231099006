import styled from "styled-components";

export const StyledSlider = styled.div`
  height: 250px;
  max-width: 125px;
  display: flex;
  flex-direction: ${(props) =>
    props.orientation === "vertical" ? "column" : "row"};
  justify-content: center;
  text-align: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.dark};
  background-color: ${({ theme }) => theme.colors.white}75;
  padding: 0.5rem;
  font-size: 0.9rem;

  .MuiSlider-root {
    margin: 0.5rem !important;
    color: ${({ theme }) => theme.colors.dark};
    position: relative;
    left: -10px;
  }
  & > h3,
  #clear, #apply {
    text-align: center;
    margin: 0;
    /* margin-bottom: 0.5rem; */
    font-size: 1rem;
    font-weight: 100;
    letter-spacing: 0.1rem;
  }
  #clear, #apply  {
    font-size: 1rem;
    padding: 0.25rem 1rem;
    min-width: 75px;
    border-radius: 0.25rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.grey};

    &:hover {
    /* background: ${({ theme }) => theme.colors.middle}; */
    /* color: ${({ theme }) => theme.colors.dark}; */
    box-shadow: 1px 1px 4px ${({ theme }) => theme.colors.dark};
    cursor: pointer;
  }
  }
  #apply {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.light};
  }
  & > h3 {
    margin-bottom: 0.5rem;
    font-weight: 300;
  }
  #inputs {
    display: flex;
    width: 90%;
  }
  .MuiInputBase-input, .MuiSlider-markLabel {
    text-align: center;
    font-weight: 100;
    font-size: 0.75rem;
  }
  .MuiSlider-thumb {
    height: 16px;
    width: 16px;
    left: 18px;
    border-radius: 50%;
    margin-left: unset;
    background-color: ${({ theme }) => theme.colors.light}e0;
    /* &::after{
      left: 4px;
    } */
  }
  .MuiSlider-track{
    height: 0.25rem;
  }
  #1{
    margin-left: 0.1rem;
  }
`;
