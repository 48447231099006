import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Auth } from "aws-amplify";

import { Loader } from "../../common";
import { theme } from "../../../theme";

import IconButton from "@material-ui/core/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faBars } from "@fortawesome/free-solid-svg-icons";

import { useAppContext, useDrawerContext } from "../../../context";

import logo from "../../../assets/logo2.png";
import { StyledNavBar } from "./NavBar.styled";

function NavBar() {
  const history = useHistory();
  const context = useAppContext();
  const contextDrawer = useDrawerContext();

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    if (history.location.pathname === "/") {
      setSticky(true);
    }
  }, []);

  const handleClick = () => {
    contextDrawer.actions.setDrawer("menu");
  };

  return (
    <StyledNavBar id="navBar" sticky={sticky}>
      <IconButton
        aria-label="landingIcon"
        id="landingIcon"
        className="icon-button"
        onClick={() => {
          history.push("/");
        }}
      >
        <img src={logo} className="icons" id="navlogo" alt="navlogo" />
      </IconButton>

      <p id="name">Luminote</p>
      {context.loading ? (
        <Loader
          className="icons"
          id="userIcon"
          mode="Bars"
          color={theme.colors.white}
        />
      ) : (
        <React.Fragment>
          {context.user && (
            <IconButton
              aria-label="userIcon"
              id="userIcon"
              className="icon-button"
              onClick={handleClick}
            >
              <FontAwesomeIcon className="icons" icon={faBars} />
            </IconButton>
          )}
          {!context.user && (
            <IconButton
              aria-label="userIcon"
              id="userIcon"
              className="icon-button"
              onClick={() => Auth.federatedSignIn()}
            >
              <FontAwesomeIcon
                aria-label="userIcon"
                id="userIcon"
                className="icons"
                icon={faUserCircle}
              />
            </IconButton>
          )}
        </React.Fragment>
      )}
    </StyledNavBar>
  );
}

export default NavBar;
