import React, { useEffect, useState } from "react";

import { useModalContext } from "../../../context";
import { ModalSwitch } from "./Modal.switch";
import { Modal } from "./Modal";

export function ModalWrapper() {
  const contextModal = useModalContext();
  const [children, setChildren] = useState(false);

  useEffect(() => {
    if (typeof contextModal.modal === "string") {
      // look up modal content refs
      setChildren(<ModalSwitch mode={contextModal.modal} />);
    } else {
      setChildren(contextModal.modal);
    }
    return function () {
      setChildren(false);
    };
  }, [contextModal.modal]);
  return (
    <Modal
      children={children}
      dismiss={() => contextModal.actions.setModal(false)}
    />
  );
}
