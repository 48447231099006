import React, { useCallback, useState, useEffect } from "react";
import Fuse from "fuse.js";

import IconButton from "@material-ui/core/IconButton";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import FilterListIcon from "@material-ui/icons/FilterList";
import BackspaceIcon from "@material-ui/icons/Backspace";

import { useListContext } from "../Context";
import { StyledSearch } from "./Search.styled";

export function Search({ showFilter }) {
  const listContent = useListContext();

  const [txt, setTxt] = useState("");

  useEffect(() => {
    // was needed for when the clear button triggers
    setTxt(listContent.searchText);
  }, []);

  const debounce = (func, delay) => {
    // used to delay the researching
    var timerId;
    return (...args) => {
      // Cancels the setTimeout method execution
      clearTimeout(timerId);
      // Executes the func after delay time.
      timerId = setTimeout(() => func(...args), delay);
    };
  };

  const executeSearch = (input, inputList) => {
    if (input === "" || !input) {
      // if input is nothing (cleared)
      listContent.actions.setListSearch(false);
      listContent.actions.setSearchText("");
      return;
    }
    // Variable to hold the filtered list before putting into state
    const fuse = new Fuse(inputList, listContent.options);
    const results = fuse.search(input);
    // inputList.forEach(checkAndPush, { output: outputList, input: input });
    const outputList = results.map((point) => point.item);

    listContent.actions.setDataLoaded(10);
    listContent.actions.setSearchResult(outputList);
    listContent.actions.setSearchText(input);
  };

  const debouncedExecute = useCallback(debounce(executeSearch, 500), []);

  const onChange = async (e) => {
    setTxt(e.target.value);
    listContent.actions.setBrowseScroll(0);
    debouncedExecute(e.target.value, listContent.fullData);
  };

  const handleScrollReset = () => {
    window.scrollTo(0, 0);
  };

  const handleReset = async () => {
    await executeSearch("");
    setTxt("");
    window.scrollTo(0, 0);
  };

  // console.log(
  //   listContent.fullData.length
  //     ? `${listContent.fullData.length} limited`
  //     : "none limited"
  // );
  return (
    <StyledSearch
      id="search"
      filtersActive={listContent.filtersActive.length ? true : false}
    >
      <IconButton
        id="to-top"
        className="head-item"
        aria-label="to-top"
        onClick={handleScrollReset}
      >
        <ArrowUpwardIcon id="to-top-icon" />
      </IconButton>

      <input
        type="text"
        placeholder="search"
        id="searchbar"
        value={txt}
        onChange={onChange}
      />
      {txt && (
        <IconButton id="clear" aria-label="clear" onClick={handleReset}>
          <BackspaceIcon id="clear-icon" />
        </IconButton>
      )}
      <p id="count" className="head-item">
        {listContent.dataLimited.length}
      </p>
      {showFilter && (
        <IconButton
          id="filter"
          aria-label="filter"
          className="head-item"
          onClick={() =>
            listContent.actions.setFiltersOpen(!listContent.filtersOpen)
          }
        >
          <FilterListIcon id="filter-icon" />
        </IconButton>
      )}
    </StyledSearch>
  );
}
