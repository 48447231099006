import React from "react";

import { useSnackContext, useModalContext } from "../../../../context";

import { NewPwdForm } from "./Forms/NewPwdForm";
import { StyledNewPwd } from "./NewPwd.styled";

const NewPwd = () => {
  const contextSnack = useSnackContext();
  const contextModal = useModalContext();

  const handleNewPwd = (resp, status) => {
    let message = "";
    if (status === "success") {
      message = "quick! go get a pwd manager";
    } else {
      message = resp["message"];
    }
    contextSnack.actions.setSnackMessage(message);
    contextSnack.actions.setSnack(status);

    if (status === "success") {
      contextModal.actions.setModal("signin");
    }
  };

  return (
    <StyledNewPwd>
      <h4 id="heading">Auth is annoying. Here's a dog.</h4>
      <img src="https://picsum.photos/id/1062/200/300?grayscale" alt="puppy" />
      <NewPwdForm handleNewPwd={handleNewPwd} />
    </StyledNewPwd>
  );
};

export default NewPwd;
