import styled from "styled-components";

export const StyledPresent = styled.div`
  animation: createPage 1s;
  /* max-height: 100%; */
  /* margin: 0.5rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.white}c4;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.middle};
  font-weight: 300;
  font-size: 1.5rem; */

  /* span {
    font-size: 2rem;
  } */

  @keyframes createPage {
    from {
      /* max-height: 0; */
      opacity: 0;
    }
    to {
      /* max-height: inherit; */
      opacity: 1;
    }
  }
`;
