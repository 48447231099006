import React, { useState, useContext } from "react";

export const SnackContext = React.createContext(null);

export function useSnackContext() {
  return useContext(SnackContext);
}
export function SnackProvider(props) {
  // console.log("---Rerendered Snack Context");

  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState(false);
  return (
    <SnackContext.Provider
      value={{
        snack: snack,
        snackMessage: snackMessage,
        actions: {
          setSnack: setSnack,
          setSnackMessage: setSnackMessage,
        },
      }}
    >
      {props.children}
    </SnackContext.Provider>
  );
}

export const Consumer = SnackContext.Consumer;
