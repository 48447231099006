import React, { useState, useEffect } from "react";

import { useListContext } from "../../Context";
import { SingleSlider, DualSlider } from "../../../../common";
import { StyledFilter } from "./Filter.styled";

export function Filter({ popdown, setPopdown }) {
  // const contextApp = useContext(AppContext);
  const listContent = useListContext();

  // set filter settings
  const [settings, setSettings] = useState({});
  const [attrIndex, setAttrIndex] = useState(false);

  useEffect(() => {
    //look up this attributes filter
    var idx = listContent.filters.findIndex((x) => x.Attribute === popdown);
    setAttrIndex(idx);

    if (Number.isInteger(idx)) {
      setSettings(listContent.filters[idx]);
    }
    return function () {
      var filtersUpdated = listContent.filters;
      filtersUpdated[attrIndex] = settings;
      listContent.actions.setFilters(filtersUpdated);
      listContent.actions.setTrigger(!listContent.trigger);
    };
  }, [listContent.filters, popdown]);

  const handleUpdate = (newValue, active, close) => {
    var updated = settings;
    updated["Value"] = newValue;
    updated["Active"] = active;

    setSettings(updated);
    window.scrollTo(0, 0);
    if (close) {
      setPopdown(false);
    }
  };

  return (
    <StyledFilter>
      {settings && [
        settings["Type"] === "bool" ? (
          <SingleSlider handleUpdate={handleUpdate} settings={settings} />
        ) : (
          <DualSlider handleUpdate={handleUpdate} settings={settings} />
        ),
      ]}
    </StyledFilter>
  );
}
